import React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, Box } from '@mui/material';

const ReusableTab = ({ tabValue, handleTabChange, accountTabs, panels }) => (
    <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
                scrollButtons="auto"
                variant="scrollable"
                orientation="horizontal"
                sx={{
                    '& .Mui-selected': {
                        color: '#E41116 !important',
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#E41116',
                    },
                }}
                onChange={handleTabChange}
                aria-label="reusable tabs component"
            >
                {accountTabs?.map((item, index) => (
                    <Tab
                        key={index}
                        sx={{ fontSize: '13px' }}
                        label={item.tabTitle}
                        value={item.tabActiveId}
                    />
                ))}
            </TabList>
        </Box>
        {panels?.map((panel, index) => (
            <TabPanel key={index} className="px-0 pt-2" value={panel.value}>
                {panel.content}
            </TabPanel>
        ))}
    </TabContext>
);

export default ReusableTab;
