import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, CircularProgress, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ApiService from '../../_services/ApiService';
import { useState } from 'react';
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { useDispatch } from 'react-redux';
import { validateISRC } from './helperUPCISRC';
import { validateUPC } from './helperUPCISRC';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useEffect } from 'react';

export default function AlbumSongMetaDialog({ getAlbumData, albumsongData, open, setOpen }) {
    const [fieldValue, setFieldValue] = useState('')
    const [error, setError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch()
    const today = dayjs();
    const handleClose = () => {
        setOpen(false);
        setError(false);
        setLoader(false)
        setFieldValue('')
    };

    useEffect(()=>{
        if(albumsongData?.value?.isrc_code != "" && albumsongData?.value?.isrc_code != null){
            setFieldValue(albumsongData?.value?.isrc_code)
        }

    },[albumsongData?.value])

    const handleInputChange = (e) => {
        const { value, name } = e.target;
        setFieldValue(value);
        if (e.type == 'blur') {
            if (name == "isrc") {
                validateISRC(value)
                const isrcValidation = validateISRC(value);
                if (isrcValidation.isValid) {
                    setError(isrcValidation.isValid);
                    setErrorMessage(isrcValidation.errorMessage);
                } else {
                    setError(isrcValidation.isValid);
                    setErrorMessage(isrcValidation.errorMessage);
                }
            }
            if (name == 'upc') {
                validateUPC(value)
                const upcValidation = validateUPC(value);
                if (upcValidation.isValid) {
                    setError(upcValidation.isValid);
                    setErrorMessage(upcValidation.errorMessage);
                } else {
                    setError(upcValidation.isValid);
                    setErrorMessage(upcValidation.errorMessage);
                }
            }

        }
    };

    const releaseDateChangeHandler = (newValue) => {
        const formattedDate = newValue.format('YYYY-MM-DD');
        setFieldValue(formattedDate);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let getEndpoint = albumsongData.label == 'upc' || albumsongData.label == 'release' ? "updateUpc" : "updateIsrc"
        let getId = albumsongData.label == 'upc' || albumsongData.label == 'release' ? albumsongData?.value?.album_id : albumsongData?.value?.song_id
        let payload = albumsongData.label == 'upc' ? { upc_code: fieldValue } : albumsongData.label == 'isrc' ? { isrc_code: fieldValue } : { release_date: fieldValue }

        if (!error) {
            setLoader(true)
            ApiService.put(getEndpoint, payload, '', getId).then((res) => {
                if (res.status == 200) {
                    dispatch(setSnackBar(true));
                    dispatch(setSnackBarVariant('success'));
                    dispatch(setSnackBarMessage(`${albumsongData.label == 'upc' ? 'Album UPC updated successfully' : albumsongData.label == 'isrc' ? 'Song ISRC updated successfully' : 'Album Release date updated successfully'}`));
                    setTimeout(() => dispatch(setSnackBar(false)), 5000);
                    handleClose()
                    getAlbumData()
                }
            }).catch((error) => {
                console.log("Error");
                handleClose()
            })
        }
    }

     console.debug("qshbwqdkbjdkq",albumsongData)
    return (
        <div>
            <Dialog fullWidth maxWidth={'sm'} onClose={handleClose} open={open}>
                <DialogTitle className='d-flex align-items-center justify-content-between'>
                    Add {albumsongData?.label == 'upc' ? 'Album UPC/EAN/JAN' : albumsongData?.label == 'isrc' ? 'Song ISRC' : 'Update Release Date'}
                    <CloseIcon className='cursorPointer' onClick={() => handleClose()} />
                </DialogTitle>
                <DialogContent>
                    <Box
                        className="mt-2"
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        {albumsongData?.label == 'release' ?
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}>
                                <DemoContainer sx={{ alignItems: 'baseline', flexDirection: 'row', gap: '1rem' }} components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                    <DatePicker
                                        className='w-100'
                                        label="Release Date*"
                                        value={albumsongData?.value?.release_date ? dayjs(albumsongData?.value?.release_date) : null}
                                        name="release_date"
                                        minDate={today}
                                        onChange={(date) => releaseDateChangeHandler(date)}
                                        renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", mt: 1 }} {...params} />}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            :
                            <TextField
                                onChange={handleInputChange}
                                onBlur={handleInputChange}
                                name={albumsongData.label}
                                className='w-100'
                                id="outlined-basic"
                                label={albumsongData?.label == 'upc' ? 'Album UPC/EAN/JAN' : albumsongData?.label == 'isrc' ? 'Song ISRC' : 'Update Release Date'}
                                variant="outlined"
                                error={error}
                                value={fieldValue}
                                helperText={error ? errorMessage : ""}
                                required />}


                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} variant='contained'
                        className="rounded-pill px-4 border-0 text-white ms-1 fs-08 py-2 gn-actionbtn shadow-none"
                        disabled={(fieldValue == '' || error) ? true : false} >
                        {loader ? <CircularProgress size={23} color="inherit" />
                            :
                            'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
