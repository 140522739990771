import React, { useState } from 'react'
import { Box, Card, CardContent, CardMedia, Checkbox, Divider, Grid, Stack } from '@mui/material'
import artist from '../../img/userAdmin.png'
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter'
import { useHistory } from 'react-router-dom'
import { verificationStatusColor } from './verificationStatusColor'

const ArtistListCardLayout = ({ cardData, moduleName }) => {
  const [selected, setSelected] = useState([]);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const history = useHistory();
  const genreNames = cardData.genres.map((genre) => (<span key={genre.id}>{genre.genre}</span>));
  const genreString = genreNames.join(', ');

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  const verifyHandler = () => {
    history.push(`/artistdetail/${cardData.userHandle}`)
  }

  return (
    <Card variant="outlined" className='shadow-table mt-4' >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }} >
        <Stack direction='row' spacing={1} alignItems='center'>
          <div className='d-flex p-3 justify-content-between w-100 align-items-center'>
            <div className="item p-0 d-flex align-items-center gap-2" >
              <CardMedia
                component="img"
                sx={{ width: '56px', height: '56px', borderRadius: '50%' }}
                image={cardData.avatar ? process.env.REACT_APP_CREATORURL_MEDIA + 'media/' + cardData.avatar : artist}
              />
            {cardData.name}
            </div>
            <div>
              <div className={`${verificationStatusColor(cardData && cardData.verification_status && cardData.verification_status.verificationStatus && cardData.verification_status.verificationStatus)} verifyStatus `} >
                {cardData.verification_status ? cardData.verification_status.verificationStatus : 'Unverified'}
              </div>
            </div>
          </div>
        </Stack>
        <CardContent className='p-3' sx={{ flex: '1 0 auto' }} >
          <div>
         
            <div className='py-1'><Divider /></div>
            <div className='text-truncate cardDetailFontSize'><span style={{ fontWeight: 500, marginRight: '22px' }}>Location</span><span style={{ fontWeight: 400 }}>{cardData.city}</span></div>
            <div className='py-1'><Divider /></div>
            <div className='text-truncate cardDetailFontSize'><span style={{ fontWeight: 500, marginRight: '45px' }}>Date </span><span style={{ fontWeight: 400 }}>{getDateAsPerFormat(cardData.date_joined, "DD Mon, YYYY")}</span></div>
            {/* <div className='py-1'><Divider /></div>
            <div className='text-truncate cardDetailFontSize'><span style={{ fontWeight: 500, marginRight: '15px' }}>Expertise </span><span style={{ fontWeight: 400 }}>{cardData.expertiseLevel && cardData.expertiseLevel.expertiseLevel}</span></div> */}
          </div>
        </CardContent>
      </Box>
    </Card>

  )
}

export default ArtistListCardLayout