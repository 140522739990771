import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Radio, TextField, Typography, useMediaQuery } from '@mui/material';
import { fetchLoginUser, fetchReleaseAlbum, fetchSongReleaseList, fetchUserDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import imgPlaceHolder from '../../img/general/PlaceholderImg.webp'
import { getDateAsPerFormat, getTime } from '../../_helpers/reusablefunctions/dateSetter';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';
import { useRef } from 'react';
import { getSingleSongDetails } from '../../_services/submitAsong/getSingleSong.service';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { songApproved } from '../../_services/songApprover/postSongApprover';
import { songApproverEdit } from '../../_services/songApprover/putSongApprover';
import { getAprrover } from '../../_services/songApprover/getSongApprover';
import SnackbarContainer from '../Snackbar';
import { submitSong } from '../../_services/submitAsong/submitAsong.service';
import { albumApprover } from '../../_services/songApprover/albumApprover';
import { GetApp } from '@mui/icons-material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
import ApiService from '../../_services/ApiService';


const SongDetails = () => {
  const smScreen = useMediaQuery('(max-width:768px)')
  const lgScreen = useMediaQuery('(max-width:1280px)')
  const history = useHistory();
  const dispatch = useDispatch();
  const { songId, albumId } = useParams();
  const [buttonText, setButtonText] = useState('Lock-in');
  const [songStatus, setSongStatus] = useState('');
  const [boxEnabled, setBoxEnabled] = useState('');
  const [selectedValue, setSelectedValue] = useState('True');
  const [comment, setComment] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const scrollRef = useRef(null);
  const [error, setError] = useState('');
  const userData = useSelector(state => state.userData.userData)
  const [songDetails, setSongDetails] = useState([]);
  const [isApproved, setIsApproved] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [songInfo, setSongInfo] = React.useState()
  const [isRejected, setIsRejected] = useState(true);
  const [isHidden, setIsHidden] = useState(true);
  const [isCommentValid, setIsCommentValid] = useState(false);
  const [isLocked, setIsLocked] = useState('');

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      maxWidth: '13.9rem',
    },
  }));

  useEffect(() => {
    if (userData.id) {
      dispatch(fetchLoginUser(userData.id));
      // getData(songId);
    }
    if (songId) {
      // dispatch(fetchSongReleaseList());
      getSongDetails(userData.id, songId);
      getData(songId);
    }
  }, [songId]);


  useEffect(() => {

    if (songDetails) {
      const song = songDetails;
      setComment(song?.comment)
      setSongStatus(song.song_status);
      if (song?.song_status === 'Released') {
        setIsSubmitDisabled(true);
      }
      if (song?.song_status === 'Rejected') {
        setSelectedValue('False');
        setIsSubmitDisabled(false);
      }
      if (song?.song_status === 'In Review' && comment == "") {
        setIsSubmitDisabled(true);
      } else {
        setIsSubmitDisabled(false);

      }
    }
  }, [songDetails]);
  function updateSongMeta(postData) {
    submitSong(postData, 'UPDATE')
      .then((response) => {
        //  dispatch(fetchSongReleaseList());
        getSongDetails(userData.id, songId)
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false)), 2000);
        dispatch(setSnackBarVariant('error'));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
      });
  }
  function getSongDetails(userId, song_id) {
    dispatch(setLoader(true))
    getSingleSongDetails({ 'userId': userId, 'song_id': song_id }, 'GET_SPECIFIC_DISPLAY').then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setSongDetails(response.data.data)
        setSongInfo(response.data.data)
        setSongStatus(response.data.data.song_status)
        setComment(response.data.data.comment);
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })
  }
  function getData(songId) {
    dispatch(setLoader(true));
    // getAprrover('GET')
    ApiService.get("getParticularSongApproval", { 'song_id': songId })
      .then(function (response) {
        dispatch(setLoader(false));
        if (response.status === 200) {
          setIsApproved(response.data.data);
          let result = response.data.data.filter((item) => item.song_id.toString() === songId);
          setFilterData(result);
        } else {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        }
      })
      .catch(function (error) {
        dispatch(setLoader(false));
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000);
        return error.response;
      });
  }


  //to handle the back navigation
  const handleRouteBack = () => {
    history.push('/albumdetail/' + albumId)
  }
  const handleLockInClick = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleClick = () => {
    dispatch(setLoader(true));
    if (buttonText === 'Lock-in') {
      setButtonText('Lock-out');
      setBoxEnabled('True');
      setIsLocked('True')
      setSelectedValue('True')
    } else {
      setButtonText('Lock-in');
      setIsLocked('False')
      setBoxEnabled('False');
      setSelectedValue('True')
    }
  };

  // Update song metadata and perform related actions
  useEffect(() => {
    if (boxEnabled !== '') {
      const postData = new FormData();
      postData.append('song_id', songId);
      postData.append('is_locked', isLocked);
      updateSongMeta(postData);
      if (boxEnabled === 'True') {
        setButtonText('Lock-out');
      } else {
        setButtonText('Lock-in');
      }
    }
  }, [boxEnabled]);

  const handleCommentChange = (event) => {
    const inputValue = event.target.value;
    setComment(inputValue);
    setIsCommentValid(inputValue.length >= 30 && inputValue.length <= 1000);
  };

  const handleRadioButtonChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const releaseSongClickHandler = (e) => {
    e.preventDefault();
    if (songId) {
      var postData = {};
      if (comment) {
        postData['comment'] = comment;
      }
      postData['song_status'] = 'Released';
      setSongStatus('Released');
      postData['approver_user_id'] = userData.id;
      postData['song_id'] = songId;
      postData['is_approved'] = 'True';
      dispatch(setSnackBar(true));
      dispatch(setSnackBarVariant('info'));
      dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdating));
      dispatch(setLoader(true));
      songApproverEdit(songId, postData)
        .then(function (response) {
          if (response.status === 200) {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('success'));
            dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdatingSuccess));
            setTimeout(() => dispatch(setSnackBar(false)), 2000);
            dispatch(fetchUserDetail());
            getData(songId);
            getSongDetails(userData.id, songId)
            setComment(response.data.comment);
            setSongStatus(response.data.song_status);
            setIsSubmitDisabled(true);
            // dispatch(fetchSongReleaseList());
          } else {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
          }
        })
        .catch((error) => {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('error'));
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        });
    }
  }

  const [audioUrl, setAudioUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    // Fetch the audio URL
    fetch(songInfo?.song_file)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
      })
      .catch(error => console.error('Error fetching audio:', error));

    // Fetch the image and convert it to base64
    const imageUrl = songInfo && songInfo.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + songInfo.cover_image : '';
    let imageHtml = '';
    fetch(songInfo?.cover_image)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          imageHtml = `<img src="${base64data}" alt="Cover Image" style="width: 200px; height: auto;" />`;
          setImageUrl(base64data);
        };
      })
      .catch(error => console.error('Error fetching image:', error));
  }, [songInfo?.song_file, songInfo?.cover_image != undefined]);

  const handleDownload = () => {
    // Create a blob with song metadata
    const blob = new Blob([
      `Song Title: ${songInfo?.song_name}
                           \nExplicit Content:${songInfo?.explicit_content ? 'Yes' : 'No'}
                           \nGenre: ${songInfo?.genre}
                           \nMood:${songInfo?.mood}
                           \nISRC Code:${songInfo?.isrc_code}
                           \nSub Genre:${songInfo?.sub_genre}
                           \nInstrumental:${songInfo?.instrumental ? 'Yes' : 'No'}
                           \nUPC Code:${songInfo.upc_code != null ? songInfo.upc_code : ""}
                           \nPrimary Artist: ${songInfo.primary_artists.length > 0 && songInfo.primary_artists[0].artist_name}
                           \nFeaturing Artist: ${songInfo.featuring_artists.length > 0 && songInfo.featuring_artists[0].artist_name}
                           \nComposer:${songInfo.composers.length > 0 && songInfo.composers[0].artist_name}
                           \nProducer:${songInfo.producers.length > 0 && songInfo.producers[0].artist_name}
                           \nActor:${songInfo.actors.length > 0 && songInfo.actors[0].artist_name}
                           \nLyricist:${songInfo.lyricists.length > 0 && songInfo.lyricists[0].artist_name}
                           \nRealease Date:${songInfo.release_date ? getDateAsPerFormat(songInfo.release_date, "DD Mon, YYYY") : ''}
                           \nRecord Label:${songInfo.record_labels && songInfo.record_labels.join(',')}
                           \nLanguage:${songInfo.language && songInfo.language}
                           \nRelease Time:${songInfo.release_time ? getTime(songInfo.release_time) : ''}
                           \nSong Lyrics:${songInfo.song_lyrics ? songInfo.song_lyrics : ''}
                           \nSong Description:${songInfo.song_description ? songInfo.song_description : ''}
                           \n© Copyright Year": ${songInfo.copyright_c ? songInfo.copyright_c && songInfo.copyright_c.copyright_year.copyright_year : "--"}
                           \n© Copyright : ${songInfo.copyright_c ? songInfo.copyright_c.copyright_name : "--"}
                           \n℗ Publishing Year: ${songInfo.copyright_p ? songInfo.copyright_p && songInfo.copyright_p.copyright_year.copyright_year : "--"}
                           \n℗ Copyright: ${songInfo.copyright_p ? songInfo.copyright_p.copyright_name : "--"}
                           \nHas it been previously released?: ${songInfo.release_history === 'False' || songInfo.release_history === false ? 'No' : 'Yes'}
                           \nPrevious Release Date:${(songInfo.release_history === 'False' || songInfo.release_history === false) ? "--" : songInfo.previous_release_date == null || songInfo.previous_release_date == '' ? '--' : getDateAsPerFormat(songInfo.previous_release_date, "DD Mon, YYYY")}
                         

                           ` ], { type: 'text/plain' });

    const url = URL.createObjectURL(blob);

    // Create a hidden link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = `${songInfo.song_name}.doc`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const downloadImage = () => {
    const imageUrl = songInfo && songInfo.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + songInfo.cover_image : imgPlaceHolder;

    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', songInfo.song_name + ".jpg");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        console.error('Error downloading image:', error);
      });
  };

  const handleSubmit = (e, albumId) => {
    e.preventDefault();
    if (!isCommentValid && selectedValue === "False") {
      setError('Comment must be between 30 and 1000 characters');
      return;
    }
    setError('');
    if (songId) {
      dispatch(setSnackBar(true));
      dispatch(setSnackBarVariant('info'));
      dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdating));
      dispatch(setLoader(true));
      if (filterData.length > 0) {
        var postData = {};

        if (selectedValue === 'True') {
          postData['comment'] = "Approved";
          postData['song_status'] = 'Ready for Release';
        } else {
          postData['song_status'] = 'Rejected';
          postData['comment'] = comment;
          setSelectedValue('False')
        }
        setSongStatus(postData.song_status);
        postData['approver_user_id'] = userData.id;
        postData['song_id'] = songId;
        postData['is_approved'] = selectedValue;
        songApproverEdit(songId, postData)
          .then(function (response) {
            if (response.status === 200) {
              dispatch(setLoader(false));
              dispatch(setSnackBarVariant('success'));
              dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdatingSuccess));
              setTimeout(() => dispatch(setSnackBar(false)), 2000);
              dispatch(fetchUserDetail());
              getData(songId);

              if (selectedValue === 'True') {
                submitButtonText = 'Release';
              } else {
                setIsRejected(true);
                setIsHidden(true)
                setIsLocked('False')
                getData(songId);
                setComment(response.data.comment);
                setSongStatus(response.data.song_status);
              }
              // dispatch(fetchSongReleaseList());
              getSongDetails(userData.id, songId)
            } else {
              dispatch(setLoader(false));
              dispatch(setSnackBarVariant('error'));
              dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
              setTimeout(() => dispatch(setSnackBar(false)), 3000);
            }
          })
          .catch((error) => {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
          });
      } else {
        var postData = {};

        if (selectedValue === 'True') {
          postData['comment'] = "Approved";
          postData['song_status'] = 'Ready for Release';
        } else {
          postData['song_status'] = 'Rejected';
          postData['comment'] = comment;
          setSelectedValue('False')
        }
        setSongStatus(postData.song_status);
        postData['approver_user_id'] = userData.id;
        postData['song_id'] = songId;
        postData['is_approved'] = selectedValue;
        songApproved(postData, userData.id)
          .then(function (response) {
            if (response.status === 200) {
              if (selectedValue === 'False') {
                var albumPostData = {};
                albumPostData['song_status'] = 'Rejected';
                albumPostData['approver_user_id'] = userData.id;
                albumPostData['album_id'] = albumId;
                // albumApprover(albumId, albumPostData)
                ApiService.post("albumpostApproval", albumPostData)
                  .then(function (response) {
                    if (response.status === 200) {
                      // dispatch(fetchReleaseAlbum({ limit: 25, offset: 0 }, []))
                    }
                  })
              }
              dispatch(setLoader(false));
              dispatch(setSnackBarVariant('success'));
              dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdatingSuccess));
              setTimeout(() => dispatch(setSnackBar(false)), 2000);
              dispatch(fetchUserDetail());
              getData(songId);
              if (selectedValue === 'True') {
                submitButtonText = 'Release';
              } else {
                setIsRejected(true);
                setIsHidden(true)
                setIsLocked('False')
                setComment(response.data.data[0].fields.comment);
                setSongStatus(response.data.data[0].fields.song_status);
              }
              // dispatch(fetchSongReleaseList());
              getSongDetails(userData.id, songId)
            } else {
              dispatch(setLoader(false));
              dispatch(setSnackBarVariant('error'));
              dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
              setTimeout(() => dispatch(setSnackBar(false)), 3000);
            }
          })
          .catch((error) => {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
          });
      }
    }
  };

  if (!songInfo) {
    return <h1>Loading...</h1>;
  }

  let submitButtonText = 'Approve';
  if (selectedValue === 'True') {
    submitButtonText = 'Approve';
  } else if (selectedValue === 'False') {
    submitButtonText = 'Reject';
  }



  return (
    <div style={{ padding: '1rem 1rem 3rem' }} >
      <div className="d-flex justify-content-between align-items-center">
        <div className=''>
          <button className="icononly-btn d-flex" onClick={handleRouteBack}>
            <ArrowBackIcon /> <p className='cardheading ms-2'>{songInfo?.album?.[0]?.album_name}</p>
          </button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
          {/* <Tooltip> */}
          <BootstrapTooltip title='Download Song Meta' ransitioncomponent={Zoom} >
            <button className="gn-actionbtn"
              onClick={handleDownload}>
              <GetApp sx={{ width: "18px" }} />
            </button>
          </BootstrapTooltip>
          {/* </Tooltip> */}
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <button
            className="gn-actionbtn"
            onClick={handleClick}
            disabled={songStatus === 'Ready for Release' ? true : false}
            style={{ visibility: songStatus === "Released" || songStatus === 'Rejected' || songStatus === 'draft'? 'hidden' : 'visible' }}>
            {buttonText}
          </button>
          </div> */}
      </div>
      <div className="mt-4">
        <div className={`d-flex flex-wrap justify-content-between ${smScreen ? '' : 'px-1'}`}>
          <div className="verifyCol1 p-4 shadow bg-white rounded">
            <div className={`d-flex ${smScreen ? 'flex-wrap' : ''}`}>
              {/* <button onClick={handleDownload}>Download</button> */}
              {/* <div className={`${smScreen ? 'w-100' : 'w-50'}`} style={{ position: 'relative' }}>
              {songInfo.cover_image != null ? <a href="#" onClick={() => downloadImage(songInfo && songInfo.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + songInfo.cover_image : imgPlaceHolder)}>
                  <img src={songInfo && songInfo.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + songInfo.cover_image : imgPlaceHolder} className="w-100 rounded" alt="Not found" />
                  <button style={{ position: 'absolute', bottom: '5px', right: '5px', background: 'rgba(255, 255, 255, 0.7)', borderRadius: '2px', border: 'none', cursor: 'pointer' }}>
                    <GetApp sx={{ width: "18px" }} />
                  </button>
                </a>: <img src={songInfo && songInfo.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + songInfo.cover_image : imgPlaceHolder} className="w-100 rounded" alt="Not found" />}
              </div> */}
              <div className={`flex-grow-1 ${smScreen ? 'w-100' : 'w-50'}`}>
                <div className="d-flex align-items-center">
                  <span className="fs-6 text-secondary">New Release</span>
                  <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                </div>
                <div>
                  <div>
                    <div className="value-pair">
                      <strong>Song Name: </strong>
                      <span>{songInfo.song_name}</span>
                    </div>
                    <div>
                      <div className="value-pair">
                        <div style={{ backgroundColor: getSongStatusBgColor(songStatus) }} className="status text-light">{songStatus == "Ready for Release" ? 'Approved' : songStatus}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* row 2 */}
            <div>
              <div className="my-3">
                <AudioPlayer songTitle={songInfo?.song_name} type='small' audioSrc={process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + songInfo.song_file} />
              </div>
            </div>
            {/* row 3 */}
            <div className="d-flex justify-content-between flex-wrap">
              <div className={`${smScreen ? 'w-100' : 'w-50'}`}>
                <div className="value-pair">
                  <strong>Explicit Content: </strong>
                  <span>{songInfo.explicit_content ? 'Yes' : 'No'}</span>
                </div>
                <div className="value-pair">
                  <strong>Genre: </strong>
                  <span>{songInfo.genre}</span>
                </div>
                <div className="value-pair">
                  <strong>Mood: </strong>
                  <span>{songInfo.mood}</span>
                </div>
                <div className="value-pair">
                  <strong>ISRC Code: </strong>
                  <span>{songInfo.isrc_code}</span>
                </div>
                <div className="value-pair">
                  <strong>Ⓟ Publishing Year: </strong>
                  <span> {songInfo.copyright_p && songInfo.copyright_p.copyright_year.copyright_year}</span>
                </div>
                <div className="value-pair">
                  <strong>© Copyright Year:</strong>
                  <span>{songInfo.copyright_c && songInfo.copyright_c.copyright_year.copyright_year}</span>
                </div>
              </div>
              <div className={`${smScreen ? 'w-100' : 'w-50'}`}>
                <div className="value-pair">
                  <strong>Sub-Genre: </strong>
                  <span>{songInfo.sub_genre}</span>
                </div>
                <div className="value-pair">
                  <strong>Instrumental: </strong>
                  <span>{songInfo.instrumental ? 'Yes' : 'No'}</span>
                </div>
                <div className="value-pair">
                  <strong>UPC Code: </strong>
                  <span>{songInfo.upc_code}</span>
                </div>
                <div className="value-pair">
                  <strong>Release History: </strong>
                  <span>{songInfo.release_history ? "Yes" : "No"}</span>
                </div>
                <div className="value-pair">
                  <strong>Ⓟ Copyright:</strong>
                  <span>{songInfo.copyright_p && songInfo.copyright_p.copyright_name}</span>
                </div>
                <div className="value-pair">
                  <strong>© Copyright:</strong>
                  <span>{songInfo.copyright_c && songInfo.copyright_c.copyright_name}</span>
                </div>
                {/* <div className="value-pair">
                  <strong>© Copyright:</strong>
                  <span>{songInfo.copyright_c && songInfo.copyright_c.copyright_name} {songInfo.copyright_c && songInfo.copyright_c.copyright_year.copyright_year}</span>
                </div>
                <div className="value-pair">
                  <strong>© Copyright:</strong>
                  <span>{songInfo.copyright_c && songInfo.copyright_c.copyright_name} {songInfo.copyright_c && songInfo.copyright_c.copyright_year.copyright_year}</span>
                </div> */}
              </div>
            </div>
          </div>
          {/* space */}
          <div style={{ width: '20px', height: '20px' }}></div>
          {/* column2 */}
          <div className="verifyCol2 p-4 shadow bg-white rounded">
            <div className="d-flex align-items-center">
              <span className="fs-6 text-secondary">Song Credit</span>
              <hr className="ms-2" style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
            </div>
            <div className="d-flex justify-content-between flex-wrap">
              <div className={`${smScreen ? 'w-100' : 'w-50'}`}>
                <div className="value-pair">
                  <strong>Primary Artist: </strong>
                  <span>{songInfo.primary_artists.map((artist) => artist.artist_name).join(', ')}</span>
                </div>
                <div className="value-pair">
                  <strong>Composer: </strong>
                  <span>{songInfo.composers.map((artist) => artist.artist_name).join(', ')}</span>
                </div>
                <div className="value-pair">
                  <strong>Producer: </strong>
                  <span>{songInfo.producers.map((artist) => artist.artist_name).join(', ')}</span>
                </div>
                <div className="value-pair">
                  <strong>Actor: </strong>
                  <span>{songInfo.actors.map((artist) => artist.artist_name).join(', ')}</span>
                </div>
                {/* <div className="value-pair">
                  <strong>Release Date: </strong>
                  <span>{songInfo && songInfo.release_date ? getDateAsPerFormat(songInfo.release_date, "DD Mon, YYYY") : ''}</span>
                </div> */}
              </div>
              <div className={`${smScreen ? 'w-100' : 'w-50'}`}>
                <div className="value-pair">
                  <strong>Featuring Artist: </strong>
                  <span>{songInfo.featuring_artists.map((artist) => artist.artist_name).join(', ')}</span>
                </div>
                <div className="value-pair">
                  <strong>Lyricist: </strong>
                  <span>{songInfo.lyricists.map((artist) => artist.artist_name).join(', ')}</span>
                </div>
                {/* <div className="value-pair">
                  <strong>Record Label: </strong>
                  <span>{songInfo.record_labels && songInfo.record_labels.join(',')}</span>
                </div> */}
                <div className="value-pair">
                  <strong>Language: </strong>
                  <span>{songInfo.language}</span>
                </div>
                {/* <div className="value-pair">
                  <strong>Release Time: </strong>
                  <span>{songInfo.release_time ? getTime(songInfo.release_time) : ''}</span>
                </div> */}
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap">
              <div className={`w-100`}>
                <div className="value-pair">
                  <strong>Song Lyrics: </strong>
                  <span>{songInfo.song_lyrics}</span>
                </div>
                <div className="value-pair">
                  <strong>Singer: </strong>
                  <span>{songInfo.singers.map((artist) => artist.artist_name).join(', ')}</span>
                  </div>
                <div className="value-pair">
                  <strong>Song Description: </strong>
                  <span>{songInfo.song_description}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={scrollRef}>

          <div className={`d-flex flex-wrap justify-content-between ${smScreen ? 'px-0' : 'px-3'} mt-4 mb-4`}>
            <div style={{ width: '20px', height: '20px', display: `${lgScreen ? 'none' : 'block'}` }}></div>
            <div className="verifyCol2 p-4 shadow bg-white rounded">
              <div className="d-flex align-items-center">
                <span className="fs-6 text-secondary">Approval</span>
                <hr className="ms-2" style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
              </div>

              <div>
                <p style={{ fontSize: '12.8px' }}>Song metadata approved or rejected based on compliance with standards and accuracy of information.</p>
              </div>
              {songStatus === 'In Review' ? (
                <React.Fragment>
                  <div className="col-md-6">
                    <Radio checked={selectedValue === 'True'} onChange={handleRadioButtonChange} value="True" inputProps={{ 'aria-label': 'Approved' }} />
                    Approve
                    <Radio checked={selectedValue === 'False'} onChange={handleRadioButtonChange} value="False" inputProps={{ 'aria-label': 'Rejected' }} />
                    Reject
                  </div>
                  {selectedValue === "False" ? <Box component="form" noValidate autoComplete="off">
                    <div>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Comment"
                        fullWidth
                        disabled={!selectedValue}
                        value={comment}
                        error={!!error}
                        helperText={error || ''}
                        multiline
                        maxRows={4}
                        onChange={handleCommentChange}
                        placeholder="Add minimum of 30 characters..."
                        required
                      />
                    </div>
                  </Box> : ""}
                  <div className="pt-4">
                    <button className="gn-actionbtn" disabled={isSubmitDisabled && (comment == "" && selectedValue === 'False')} onClick={(e) => handleSubmit(e, albumId)}>
                      {submitButtonText}
                    </button>
                  </div>
                </React.Fragment>) : (
                <>
                  <Box component="form" noValidate autoComplete="off">
                    <div>
                      <Typography variant="caption" fontWeight={500}>{comment}</Typography>
                    </div>
                  </Box>
                  {songStatus === 'Rejected' ?
                    (<div className="pt-4">
                      <button className="gn-actionbtn" disabled>
                        {submitButtonText}
                      </button>
                    </div>)
                    : songStatus == 'Ready for Release' ?
                      (
                        <div className='fs-08 status text-light' style={{ backgroundColor: getSongStatusBgColor(songStatus) }}>{songStatus == "Ready for Release" ? 'Approved' : songStatus}</div>

                      )
                      :
                      (""
                        //   <div className="pt-4">
                        //   <button className="gn-actionbtn" onClick={releaseSongClickHandler} disabled={isSubmitDisabled}>
                        //     Release
                        //   </button>
                        // </div>
                      )
                  }
                </>
              )}
            </div>
          </div>

        </div>
      </div>
      <SnackbarContainer />
    </div>
  );
};

export default SongDetails;