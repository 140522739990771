import React, { useState } from 'react';
import Dashboard from '../dashboard/dashboard';
import Footer from '../../admin/footer';
import banner6 from '../../img/service2.png';
import banner5 from '../../img/service1.png';
import banner1 from '../../img/pressRelease.png';
import banner4 from '../../img/lyrics.png';
import banner2 from '../../img/blogPlacement.png';
import banner3 from '../../img/artist.png';
import chatbot from '../../img/chatbot.png'
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import AddToCart from './addToCart';
import { ArrowDownward, SentimentSatisfiedAltSharp } from '@mui/icons-material';
import { Search } from '@mui/icons-material';

function Services(props) {
    const[flip,setFlip]=useState(true)
    const data = [{ id: 1, banner: banner1, title: "Press Release services", subTitle: "Lorem Ipsum is simply dummy text of the printing .dummy text of the printing " },
    { id: 2, banner: banner2, title: "Blog Placement services", subTitle: "Lorem Ipsum is simply dummy text of the printing.dummy text of the printing " },
    { id: 3, banner: banner3, title: "Artist Press Kit services", subTitle: "Lorem Ipsum is simply dummy text of the printing dummy text of the printing " },
    { id: 4, banner: banner4, title: "Lyrics video service", subTitle: "Lorem Ipsum is simply dummy text of the printing   .dummy text of the printing    " },
    { id: 5, banner: banner5, title: "Service 1", subTitle: "Lorem Ipsum is simply dummy text of the printing  .dummy text of the printing     " },
    { id: 6, banner: banner6, title: "Service 2", subTitle: "Lorem Ipsum is simply dummy text of the printing  .dummy text of the printing     " }
    ]
    const [value, setValue] = React.useState(3);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);

        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p >
                {isReadMore ? text.slice(0, 40) : text}
                <Link to="#" onClick={toggleReadMore} >
                    {isReadMore ? "Show more" : " Show less"}
                </Link>
            </p>
        );
    };
    return (
        <div className="bg-light">
           
            <div className="container-fluid p-0 ">
                <div className="row">
                    <div className="mt-5 mb-3 d-flex justify-content-between">
                        <div className="input-group Btn">
                            <input className="form-control  searchBtn border-2" value="search" id="example-search-input" />
                            <span className="input-group-append">
                                <button className="btn btn-outline-secondary  border-0 ms-n5" type="button">
                                    <Search />
                                </button>
                            </span>
                        </div>
                        <span className="input-group-append ">
                            <button className="btn b     searchBtn sortBtn ms-n5 " >
                                <ArrowDownward />Sort By
                            </button>
                        </span>
                    </div>
                    <h3>Services</h3>
                    {data.map((item) =>
                        <div className="col-md-4 mb-4 " key={item.id}>
                            <div className="card border rounded ">
                                <img src={item.banner} className="w-100" />
                                <div className="card-body">
                                    <div>
                                        <h5>{item.title}</h5>
                                        
                                        <ReadMore>{item.subTitle}</ReadMore>
                                        <hr className="m-1" />
                                        <div className='pt-2'>
                                            <Rating name="read-only" defaultValue={value} readOnly/>
                                            <div className="d-flex float-end">
                                                <div className="avatars">
                                                    <span className="avatar">
                                                        <img src="https://picsum.photos/70" className='imgSize'/>
                                                    </span>
                                                    <span className="avatar">
                                                        <img src="https://picsum.photos/80" className='imgSize'/>
                                                    </span>
                                                    <span className="avatar">
                                                        <img src="https://picsum.photos/90" className='imgSize'/>
                                                    </span>
                                                    <span className="avatar">
                                                        <img src="https://picsum.photos/100" className='imgSize'/>
                                                    </span>
                                                </div>
                                                <Link to="/artists"><span className="float-end mt-1"> Artist</span></Link>
                                            </div>
                                        </div>
                                        <div className='pt-3'>
                                            <span>$50.00</span>
                                            <button className="btn btn-outline-danger fs-6 text-bold py-1 rounded-pill float-end" onClick={toggleDrawer("right", true)}> Add to cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='text-center'><button className="btn btn-secondary px-5 rounded-pill">LOAD MORE</button></div>
                    <div className="p-3 "><img className="float-end" src={chatbot} /></div>
                </div>
                <div>
                    <Drawer
                        anchor={"right"}
                        open={state["right"]}
                        onClose={toggleDrawer("right", false)}
                    >
                        <AddToCart />


                    </Drawer>
                </div>
            </div>

        </div>
        
    );
}

export default Services;