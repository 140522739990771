import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    Select,
    FormControl,
    InputLabel,
    MenuItem
} from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const PayoutModal = ({ open, payoutAmount, handleClose, submitRequest, reference_id, setReference_id, statusPaid, setStatusPaid, payment_date, paymentDateDataHandler, isFormValid }) => {

    return (
        <Modal open={open}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Requested Amount
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    <CurrencyRupeeIcon fontSize={'large'} />
                    {payoutAmount?.amount_paid}
                </Typography>

                <Box display={'flex'} alignItems={'center'} gap={2} >
                    <FormControl fullWidth={true} >
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}>
                            <DatePicker
                                views={['year', 'month', 'day']}
                                label="Payment Date *"
                                value={payment_date}
                                onChange={paymentDateDataHandler}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        sx: { minWidth: "100%", backgroundColor: '#F6F6F6' },
                                    },
                                    popper: {
                                        modifiers: [
                                            {
                                                name: "preventOverflow",
                                                options: {
                                                    boundary: "viewport", // Prevents the calendar from overflowing outside the viewport
                                                    rootBoundary: "document", // Ensures it does not get clipped
                                                    altAxis: true,
                                                    tether: false,
                                                },
                                            },
                                            {
                                                name: "flip",
                                                options: {
                                                    fallbackPlacements: ["top", "bottom"], // Tries to position it on top if bottom is not possible
                                                },
                                            },
                                        ],
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <TextField
                        label="Reference Id"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={reference_id}
                        onChange={(e) => setReference_id(e.target.value)}
                        inputProps={{ min: 0 }}
                        sx={{ my: 2 }}
                        required
                        size='small'
                    />
                    <FormControl  size='small' fullWidth>
                        <InputLabel id="demo-simple-select-label">Status *</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={statusPaid}
                            label="Status"
                            onChange={(e) => setStatusPaid(e.target.value)}
                        >
                            <MenuItem value={'Paid'}>Paid</MenuItem>
                            <MenuItem value={'On-Hold'}>On Hold</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={2} mt={2}>
                    <Button
                        variant="text"
                        className='gn-actionbtn-color'
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className='gn-actionbtn'
                        disabled={!isFormValid}
                        onClick={submitRequest}
                    >
                        Pay
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PayoutModal;
