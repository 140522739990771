import {
    FETCH_LOGIN_USER_REQUEST,
    FETCH_LOGIN_USER_SUCCESS,
    FETCH_LOGIN_USER_FAILURE,
  } from './loginUserTypes'
  import {getUser  } from '../../_services/user/getUser.service'
  export const fetchLoginUser = (id) => {
    return (dispatch) => {
      // dispatch(fetchLoginUserRequest())
      getUser(id)
        .then(response => {
          const userDetail = response.data.data[0]
          dispatch(fetchLoginUserSuccess(userDetail))
        })
        .catch(error => {
          dispatch(fetchLoginUserFailure(error.message))
        })
    }
  }
  
  export const fetchLoginUserRequest = () => {
    return {
      type: FETCH_LOGIN_USER_REQUEST
    }
  }
  
  export const fetchLoginUserSuccess = userDetail => {
    return {
      type: FETCH_LOGIN_USER_SUCCESS,
      payload: userDetail
    }
  }
  
  export const fetchLoginUserFailure = error => {
    return {
      type: FETCH_LOGIN_USER_FAILURE,
      payload: error
    }
  }
  