import React, { useState,useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useSelector, useDispatch } from 'react-redux'
import MuiAlert from '@mui/material/Alert';
import { setLoader, setSnackBar, setSnackBarMessage } from '../redux';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

function SnackbarContainer(props) {
    const snackBarData = useSelector((state) => state.snackBar);
    
    let style = {width:'100%'}
    style.backgroundColor = snackBarData.snackBarVariant?'':'#333'
    style.color = snackBarData.snackBarVariant?'':'#fff'
    const dispatch = useDispatch()
    const handleClose = () =>{
        dispatch(setSnackBar(false))
    }
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={snackBarData.snackBarToggle}
            className="snackbarColor"
            // autoHideDuration={6000} 
            message={snackBarData.snackBarMessage}
            key={snackBarData.vertical + snackBarData.horizontal}
        >
            <Alert onClose={handleClose} severity={snackBarData.snackBarVariant} sx={style}>
                {snackBarData.snackBarMessage}
            </Alert>
        </Snackbar>
    );
}

export default SnackbarContainer;