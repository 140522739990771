import React, { useEffect, useState } from "react";
import TableComponent from "./Tablecomponent";
import { Box, Link, Paper, Typography } from "@mui/material";
import { getSongStatusBgColor } from "../../../_helpers/reusablefunctions/getColor";
import { useDispatch, useSelector } from "react-redux";
import { getDateAsPerFormat } from "../../../_helpers/reusablefunctions/dateSetter";
import ApiService from "../../../_services/ApiService";
import { useLocation } from "react-router-dom";
import PayoutModal from "./PayoutModal";
import { setLoader } from "../../../redux";
export default function PayoutDetails({ studioDetail }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [historyDetailRow, setHistoryDetailRow] = useState(null);
  const [openPayout, setOpenPayout] = useState(false);
  const [reference_id, setReference_id] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const [statusPaid, setStatusPaid] = useState("");
  const getId = location.state.state.data;
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [total_count, setTotal_count] = useState(0);
  const [payment_date, setPayment_date] = useState("");

  const [isFormValid, setIsFormValid] = useState(false);

  const getPayoutDetails = async () => {
    try {
      const [payoutStatusRes] = await Promise.all([
        ApiService.get("payout_status", {
          publisher_id: getId?.studio_id,
          publisher_type: "studio",
          limit: limit,
          offset: offset,
        }),
      ]);

      // setPayoutAmount(revenueLedgerRes.data);
      setTotal_count(payoutStatusRes.data?.total_count);
      setHistoryData(payoutStatusRes.data?.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    if (getId?.studio_id) {
      // dispatch(setLoader(true));
      getPayoutDetails();
    }
  }, [getId?.studio_id]);

  const columns = [
    { id: "amount_paid", field: "amount_paid", label: "Requested Amount" },
    {
      id: "payouttype",
      label: "Payout Type",
      template: () => (
        <Box className="d-flex align-items-center">Bank Transfer</Box>
      ),
    },
    { id: "payment_date", field: "payment_date", label: "Payment Date" },
    {
      id: "requestedon",
      label: "Requested On",
      template: (row) => (
        <Box className="d-flex align-items-center">
          {getDateAsPerFormat(row.requested_date, "DD Mon, YYYY")}
        </Box>
      ),
    },
    {
      id: "status",
      label: "Status",
      headerName: "",
      flex: 1,
      template: (row) => {
        return (
          <Box className="">
            <Typography
              variant="subtitle1"
              sx={{ color: row?.status == "Paid" ? "#00C853" : "#F14950 " }}
            >
              {row?.status}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: "action",
      label: "",
      headerName: "",
      flex: 1,
      template: (row) => {
        return row?.status !== "Paid" ? (
          <Box
            className="text-decoration-none viewDetailgradient cursorPointer"
            onClick={() => {
              setOpenPayout(true);
              setHistoryDetailRow(row);
            }}
          >
            Pay Amount
          </Box>
        ) : (
          ""
        );
      },
    },
  ];

  const paymentDateDataHandler = (newValue) => {
    const formattedDate = newValue.format("YYYY-MM-DD");
    setPayment_date(formattedDate);
  };

  const validateForm = () => {
    // Check if all required fields are filled out
    if (payment_date && reference_id && statusPaid) {
      setIsFormValid(true); // Enable "Pay Amount" button if all fields are filled
    } else {
      setIsFormValid(false); // Disable "Pay Amount" button if any field is missing
    }
  };

  useEffect(() => {
    validateForm();
  }, [payment_date, reference_id, statusPaid]);

  const handleModalClose = () => {
    setOpenPayout(false);
    setStatusPaid("");
    setReference_id("");
    setPayment_date("");
  };

  const submitRequest = (e) => {
    const formData = new FormData();
    formData.append("status", statusPaid);
    formData.append("payment_date", payment_date);
    formData.append("reference_id", reference_id);

    dispatch(setLoader(true));

    ApiService.put("payout_status", formData, null, historyDetailRow?.id)
      .then(() => getPayoutDetails())
      .finally(() => {
        handleModalClose();
        dispatch(setLoader(false));
      });
  };

  return (
    <Paper sx={{ width: "100%", mb: 5, mt: 4 }}>
      <Typography variant="h6" className="p-3">
        Payout Detail
      </Typography>
      <TableComponent
        columns={columns}
        data={historyData || []}
        total_count={total_count}
        page={offset}
        setPage={setOffset}
        rowsPerPage={limit}
        setRowsPerPage={setLimit}
      />
      <PayoutModal
        payoutAmount={historyDetailRow}
        open={openPayout}
        setReference_id={setReference_id}
        reference_id={reference_id}
        handleClose={handleModalClose}
        submitRequest={submitRequest}
        statusPaid={statusPaid}
        setStatusPaid={setStatusPaid}
        paymentDateDataHandler={paymentDateDataHandler}
        isFormValid={isFormValid}
      />
    </Paper>
  );
}
