import React from 'react';
import { Box, Chip, Tooltip, Typography } from '@mui/material';

export default function GetMoodGenreLanguage({ label, items }) {
    const displayedItems = items.slice(0, 2);
    const remainingItems = items.slice(2);
  
    return (
      <Box className='d-flex align-items-center gap-2'>
        <Typography variant="body2"><strong>{label}:</strong></Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
          {displayedItems.map((item) => (
            <Chip key={item} label={item} sx={{ backgroundColor: '#E6EEEF' }} />
          ))}
          {remainingItems.length > 0 && (
            <Tooltip
              title={remainingItems.join(', ')}
              placement="top"
              arrow
            >
              <Chip
                label={`+${remainingItems.length}`}
                sx={{ backgroundColor: '#E6EEEF', cursor: 'pointer' }}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
    );
  }