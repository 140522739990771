import axios from 'axios';
import { getAppToken, getSelfToken } from '../_helpers/auth/secureToken';
const BASE_URL = process.env.REACT_APP_BASEURL;

class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {
      studioList: "user/studio-users",
      studioUser: "user/studio-user",
      studioDetail: "user/releases/studio-details/studio",
      studioSongStatus: "user/releases/studio-song-status/studio",
      contractRatio: "user/releases/contract_ratio",
      splitRevenue: "user/releases/release-collections/update",
      aggregatedData: "user/releases/aggregated-data",
      checkSplit: "user/releases/release-collections/check-split",
      searchStudio: "user/studio-user-search",
      searchSong: "user/releases/album_list",
      postRevenueData: 'user/releases/import-release-collection',

      overviewData: "/releases/overview_admin",

      trackData: "/releases/allsongs_admin",
      singleDetails: "/releases/songs_admin",

      albumData: "/releases/allalbums_admin",
      singleAlbumData: "/releases/albums_admin",

      monthData: "/releases/allmonth_admin",
      singleMonthDetails: "/releases/month_admin",

      storeData: "/releases/allplatforms_admin",
      singlestoreData: "/releases/platform_admin",

      countryData: "/releases/allcountry_admin",
      singleCountryData: "/releases/country_admin",

      videoData: "/releases/allvideo_admin",
      countryList: "/releases/country/",

      // -------------- Admin revenue start ---------------

      adminoverviewData: "/releases/overview",
      admintrackData: "/releases/allsongs",
      adminalbumData: "/releases/allalbums",
      adminstoreData: "/releases/allplatforms",
      admincountryData: "/releases/allcountry",
      adminmonthData: "/releases/allmonth",
      adminvideoData: "/releases/allvideo",
      admincountryList: "/releases/country",
      adminsingleDetails: "/releases/songs",
      adminsingleMonthDetails: "/releases/month",
      adminsingleAlbumData: "/releases/albums",
      adminsingleCountryData: "/releases/country",
      adminsinglestoreData: "/releases/platform",

      // ------------------ Admin all royalties view details --------

      adminallsingleSong: "/releases/songs_share",
      adminallsingleMonthDetails: "/releases/month_share",
      adminallsingleAlbumData: "/releases/albums_share",
      adminallsingleCountryData: "/releases/country_share",
      adminallsinglestoreData: "/releases/platform_share",

      // -------------- Admin revenue end ---------------

      deleteRevenueLog: "/releases/import-logs",
      getExcelData: "/releases/release_collection",

      advanceFilterSong: 'user/releases/albumsearchall',

      //--------------------Album Approval -----------------

      albumpostApproval: "user/albumApproval",
      albumputApproval: "user/album_verify/",
      getParticularSongApproval: "user/song_approvals",
      getParticularAlbumApproval: "user/album_approvals",

      songApproved: "user/songApproval",
      // ---------song pitch api endpoints -------
      pitchSong: 'user/releases/song_pitching',
      songMeta: 'user/releases/songmeta/studio',
      songpitchList: 'user/releases/all_song_pitching',
      songpitchView: 'user/releases/song_pitchingId/studio',
      songpitchSearch: 'user/releases/pitching_search',
      songPitchStatus: 'user/releases/song_pitching',

      // ---------------Admin upc and isrc update -----------

      updateUpc: 'user/releases/albummeta',
      updateIsrc: 'user/releases/songmeta',

      // ------------- DISCOVER ENDPOINT --------------

      allPlaylist: 'content_creator/playlist/all/',
      getSinglePlaylist: 'user/playlists',
      createEditPlaylist: 'user/playlist',
      allSongs: 'user/getSongList/',
      removeSongPlaylist: 'content_creator/playlist/removesong',
      getMood: 'user/mood/',
      getLanguage: 'user/language/',
      playlistTtrendSequence: 'content_creator/playlist-sequence/',
      songTrendSequence: 'content_creator/trending-song-sequence/',
      searchSongPlaylist: 'user/playlist/song/',
      searchPlaylist: 'user/playlist/genre/',
      search_allsongs: 'user/search_allsongs/',
      promotion_songs: 'user/promotedSongs/',
      createSongList: 'user/createSongList',

      streamingPlatforms: 'user/streamingPlatforms/',
      platformCreateUpdateDelte: 'user/playlistStreamingPlatforms',
      deleteOfficialPlaylist: 'user/playlists',
    };
  }
  async get(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      const validEndpointKeys = [
        "overviewData", "trackData", "albumData", "singleDetails", "storeData",
        "countryData", "monthData", "singleMonthDetails", "videoData", "singleAlbumData", "singleCountryData", "singlestoreData", "countryList", "adminoverviewData", "admintrackData", "adminalbumData", "adminsingleDetails", "adminstoreData",
        "admincountryData", "adminmonthData", "adminsingleMonthDetails", "adminvideoData", "adminsingleAlbumData",
        "adminsingleCountryData", "adminsinglestoreData", "admincountryList", "getExcelData", "deleteRevenueLog", 'adminallsingleSong', 'adminallsingleMonthDetails', 'adminallsingleAlbumData', 'adminallsingleCountryData', 'adminallsinglestoreData', 'allPlaylist'
      ];
      let response;
      if (validEndpointKeys.includes(endpointKey)) {
        response = await this.api.get(endpoint, {
          params,
        })
      }
      else {
        response = await this.api.get(endpoint, {
          params,
          headers: { Authorization: 'Token ' + getSelfToken() },
        });
      }
      return response;
    } catch (error) {
      // Use a template string to include the error message in the throw statement
      // throw new Error(`API request failed: ${error}`);
      console.debug("API request failed: response error", error);
      throw new Error(`API request failed: ${error.response || error}`);
    }
  }
  async post(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      const validEndpointKeys = ['removeSongPlaylist']
      let response;
      if (!validEndpointKeys.includes(endpointKey)) {
        response = await this.api.post(endpoint, data, {
          headers: { Authorization: 'Token ' + getSelfToken() }
        });
      } else {
        response = await this.api.post(endpoint, data, {
        });
      }
      return response;
    } catch (error) {
      // throw new Error('API request failed:', { error });
      console.debug("API request failed: response error", error);
      console.debug("API request failed: response error", error.response);
      return error.response;
      // throw new Error('API request failed:', error.response);
    }
  }
  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      let response;
      const validEndpointKeys = ['deleteRevenueLog']
      if (!validEndpointKeys.includes(endpointKey)) {
        response = await this.api.delete(endpoint, {
          params,
          headers: { Authorization: 'Token ' + getSelfToken() },
        });
      } else {
        response = await this.api.delete(endpoint, {
          params,
        })
      }
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null, pathParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (pathParam) {
        if (endpoint.endsWith('/')) {
          endpoint = `${endpoint}${pathParam}/`;
        } else {
          endpoint = `${endpoint}/${pathParam}/`;
        }
      }
      if (searchParam) {
        const searchParams = new URLSearchParams(searchParam).toString();
        endpoint = `${endpoint}?${searchParams}`;
      }
      if (!data) {
        data = {};
      }
      data['app'] = getAppToken();
      const validEndpointKeys = ['playlistTtrendSequence','songTrendSequence']
      let response;
      if (!validEndpointKeys.includes(endpointKey)) {
        response = await this.api.put(endpoint, data, {
          headers: { Authorization: 'token ' + getSelfToken() }
        });
      } else {
        response = await this.api.put(endpoint, data);
      }
      return response;
    } catch (error) {
      console.log(error.response);
      // return error.response;
      return error.response || { status: 500, message: error.message };
    }
  }
}

export default new APIService(BASE_URL);
