import {
    FETCH_USER_DATA_REQUEST,
    FETCH_USER_DATA_SUCCESS,
    FETCH_USER_DATA_FAILURE,
  } from './userDataTypes'
  import {getLoginUser} from '../../_services/user/getLoginUser.service'
  import {getUserId} from '../../_helpers/auth/secureToken' //to pass the user id 
  
  export const fetchUserDetail = () => {
    return (dispatch) => {
      dispatch(fetchUserDetailRequest())
      getLoginUser(getUserId())
        .then(response => {
          //const userData = response.data.data
          const userData = response.data.data[0];
          dispatch(fetchUserDetailSuccess(userData))
        })
        .catch(error => {
          dispatch(fetchUserDetailFailure(error.message))
        })
    }
  }
  
  export const fetchUserDetailRequest = () => {
    return {
      type: FETCH_USER_DATA_REQUEST
    }
  }
  
  export const fetchUserDetailSuccess = userData => {
    return {
      type: FETCH_USER_DATA_SUCCESS,
      payload: userData
    }
  }
  
  export const fetchUserDetailFailure = error => {
    return {
      type: FETCH_USER_DATA_FAILURE,
      payload: error
    }
  }
  