import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import grid from '../../img/Revenue/grid.png'
import guitarist from '../../img/Revenue/guitarist.png'
import platform from '../../img/Revenue/platform.png'
import calendar from '../../img/Revenue/calendar.png'
import ApiService from '../../_services/ApiService';
import SingleDataCard from '../../components/reusableComponents/SingleDataCard';
const ViewRevenueCard = () => {
    const viewRevenue = JSON.parse(localStorage.getItem("revenueData"));
    const [aggregatData, setAggregatData] = useState([])
    const gridStyle = {
        width: '70px',
        height: '70px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }


    useEffect(() => {
        ApiService.get('aggregatedData', {}, viewRevenue.import_id).then(response => {
            const res = response.data
            console.debug("cwhbsvhjs", res)
            if (response.status === 200) {
                const formattedData = [
                    {
                      'label': 'Rows uploaded',
                      'value': res.data?.total_fields,
                      'icon': grid,
                      'cardbg':'#F1E7E8',
                      'bgcolor':'#ED3237'
                    },
                    {
                      'label': 'No. of Artists',
                      'value': res.data.unique_artists,
                      'icon': guitarist,
                      'cardbg':'#E6EEEF',
                      'bgcolor':'#13ACCD'
                    },
                    {
                      'label': 'No. of Platforms',
                      'value': res.data.unique_platforms,
                      'icon': platform,
                      'cardbg': '#F1EAE7',
                      'bgcolor':'#FF7B08'
                    },
                    {
                      'label': 'Months',
                      'value': res.data.unique_months,
                      'icon': calendar,
                      'cardbg':'#F1EFE6',
                      'bgcolor':'#FF0D37'
                    }
                  ]
                setAggregatData(formattedData)
            }
            else {
            }
        }).catch(function (error) {
            return error.response;
        })
    }, [])

    return (
        <Box mt={3}>
            <Box sx={{ display: 'flex', gap: '20px' }}>
            {aggregatData && aggregatData.map((item, index) => (
        <SingleDataCard
          key={index} // Remember to use a unique key for each element in the array
          icon={item.icon}
          cardHeading={item.label}
          count={item.value}
          bg={item.bgcolor}
          cardbg={item.cardbg} // Assuming each value in the count array represents the count
        />
      ))}
            </Box>
        </Box>
    );
};

export default ViewRevenueCard;
