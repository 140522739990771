import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Stack, Typography, Tooltip, IconButton, Autocomplete, TextField } from '@mui/material';
import ApiService from '../../_services/ApiService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import playlist from '../../img/Frame 48096170.png'
import { styled } from '@mui/system';
import NoResults from '../../_helpers/reusablefunctions/NoResults';
import SkeletonCard from '../../_helpers/reusablefunctions/SkeletonCard';
import { useHistory } from 'react-router-dom';
import PaginationComponent from '../../_helpers/reusablefunctions/paginationComponent';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import SnackbarContainer from '../Snackbar';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { DragIndicator } from '@mui/icons-material';
import { getGenre } from '../../_services/profile';
import SearchBox from '../../components/reusableComponents/SearchBox';

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '10px',
    color: '#545454',
    background: '#fff',
    border: '0px',
    padding: '6px 20px 6px 20px',
    fontSize: '14px',
    '&:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable:focus, &.MuiChip-clickable:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable': {
        '&.Mui-active': {
            backgroundColor: '#ED1C24',
            color: '#fff',
        },
    },
}));
const discoverTabs = [
    { label: 'all', value: ["All Music"] },
    { label: 'trending', value: ['Trending Song'] },
    { label: 'artist', value: ['Trending Artist'] },
    // { label: 'promotion', value: ['Song Promotion'] },
]
export default function DiscoverMusic() {
    const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
    const history = useHistory()
    const [playlistData, setPlaylistData] = useState([])
    const [loader, setLoader] = useState(true);
    const [activeChip, setActiveChip] = useState('0-0');
    const [activeLabel, setActiveLabel] = useState('all');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const [total_count, setTotal_count] = useState(0)
    const [searchInput, setSearchInput] = useState(null)

    function playlistCall(value) {
        setLoader(true);
        let searchValue = value === 'artist' ? '' : value;
        let trending_artists = value === 'artist' ? 'true' : '';
        let pageValue = searchInput ? page : 0;
        let payload;
        let apiEndpoint;

        if (value === 'promotion') {
            apiEndpoint = 'promotion_songs';
            payload = {
                limit,
                offset: pageValue
            };
        } else {
            apiEndpoint = 'search_allsongs';
            payload = value !== 'all'
                ? { promotion: false, search: searchValue, trending_artists, limit, offset: pageValue }
                : { promotion: false, premium_users: 'True', limit, offset: pageValue };
        }

        ApiService.get(apiEndpoint, payload)
            .then((response) => {
                if (response.status === 200) {
                    let arrayData = response.data.results;
                    const data = arrayData.sort((a, b) => parseInt(a.trending_song_order) - parseInt(b.trending_song_order));
                    setPlaylistData(data);
                    setTotal_count(response?.data?.total_count);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoader(false);
            });
    }

    const handleChipClick = (i, label, value) => {
        setActiveChip(i)
        setActiveLabel(label)
        playlistCall(label)
    }

    const trendingplaylist = (id) => {
        let payload = activeLabel !== "trending" ? { type: 'trending' } : { type: '' }
        infoSnack(activeLabel !== "trending" ? formSnackbar.playlist.songTrendingAdding : formSnackbar.playlist.songTrendingRemove)
        ApiService.put('createSongList', payload, { id: id }).then(res => {
            if (res.status == '200') {
                successSnack(activeLabel !== "trending" ? formSnackbar.playlist.songTrendingAdding : formSnackbar.playlist.songTrendingRemove)
                playlistCall(activeLabel)
            }
        }).catch(error => {
            errorSnack(formSnackbar.errors.errorMsg)
            console.log(error)
        })
    }

    // ----------------- DRAG AND DROP FUNCTION -------------

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(playlistData);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setPlaylistData(items);
        const data = items.map(song => song.id)
        let dataObj = {
            song_ids: data.join(',')
        }
        ApiService.put('songTrendSequence', dataObj).then(res => {
            if (res.status == '200') {
            }
        }).catch(error => {
            errorSnack(formSnackbar.errors.errorMsg)
            console.log(error)
        })
    }

    // --------------- GENERE SEARCH FUNCTION ---------------

    function handleSearchInput(value) {
        setSearchInput(value)
    }
    function getSearchList() {
        if (searchInput) {
            ApiService.get('search_allsongs', { 'search': searchInput, limit: 25, offset: page }).then((response) => {
                if (response.status == '200') {
                    setPlaylistData(response?.data?.results);
                    setTotal_count(response?.data?.total_count)
                }

            }).catch(error => {
                console.log(error)
            })
        } else {
            playlistCall(activeLabel)
        }
    }
    useEffect(() => {
        let delaySearch;
        delaySearch = setTimeout(() => {
            getSearchList()
        }, 1000);
        return () => clearTimeout(delaySearch)
    }, [page, limit, searchInput])

    const openDetails = (data) => {
        if (activeLabel == 'artist') {
        } else {
            history.push('/music-detail', { musicPlatformData: data })
        }
    }

    const cardContainer = (data, provided) => {
        return (
            <Card className="h-100 position-relative" role="box" tabIndex="0" ref={provided?.innerRef} {...provided?.draggableProps}>
                {activeLabel == "trending" && <Box className='text-center'  {...provided.dragHandleProps} >
                    <DragIndicator />
                </Box>}

                <CardMedia
                    onClick={() => openDetails(data)}
                    component="img"
                    style={{
                        height: '200px',
                        cursor: 'pointer'
                    }}
                    alt={activeLabel == 'artist' ? data?.name : data?.songTitle}
                    image={activeLabel == 'artist' && data?.user_avatar
                        ? process.env.REACT_APP_CREATORURL_MEDIA + 'media/' + data?.user_avatar
                        : data.songPoster
                            ? process.env.REACT_APP_CREATORURL_MEDIA + 'media/' + data.songPoster
                            : playlist}
                />
                <CardContent className='pb-5'>
                    <Tooltip title={activeLabel == 'artist' ? data?.name : data.songTitle}
                        placement="top"
                        arrow> <Typography variant="body2" className='text-truncate'><strong>{activeLabel == 'artist' ? 'Artist Name' : 'Song Name:'}</strong> {activeLabel == 'artist' ? data?.name : data.songTitle}</Typography></Tooltip>
                    {
                        activeLabel != 'artist' ? <Box>
                            <Box className='d-flex align-items-center gap-2' >
                                <Typography variant="body2"><strong>Genre:</strong></Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                    {data?.genre ?
                                        <Chip key={data?.genre?.id} label={data?.genre?.genre} sx={{ backgroundColor: '#E6EEEF' }} />
                                        : '-'}
                                </Box>
                            </Box>
                            <Box className='d-flex align-items-center gap-2' >
                                <Typography variant="body2"><strong>Mood:</strong></Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                    {data?.mood ?
                                        <Chip key={data?.mood?.id} label={data?.mood?.mood} sx={{ backgroundColor: '#E6EEEF' }} />
                                        : '-'}
                                </Box>
                            </Box>
                            <Box className='d-flex align-items-center gap-2' >
                                <Typography variant="body2"><strong>Language:</strong></Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                    {data?.language ?
                                        <Chip key={data?.language?.id} label={data?.language?.language} sx={{ backgroundColor: '#E6EEEF' }} />
                                        : '-'}
                                </Box>
                            </Box>
                        </Box>
                            : null
                    }
                </CardContent >
                <CardActions className='position-absolute bottom-0' >
                    {activeLabel != 'artist' ? <Box display={'flex'} justifyContent={'space-between'} width={'100%'} >
                        <Button variant='text'
                            disabled={data?.type == "trending" && activeLabel != 'trending'}
                            sx={{ fontSize: '11px' }}
                            onClick={() => trendingplaylist(data?.id)}
                        >
                            {activeLabel != 'trending' ? 'Mark it trending song' : 'Remove from trending song'}
                        </Button>
                    </Box> : null}
                </CardActions>
            </Card >
        )
    }

    return (
        <Box sx={{ padding: '1rem 1rem 3rem 1rem' }}>
            <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} >
                <h2 style={{ marginLeft: '10px' }}>Discover-Music</h2>
            </Box>
            <Box mt={2}>
                <Box className="d-flex align-items-center justify-content-between flex-wrap gap-2 mb-3">
                    <Stack direction="row" spacing={1}>
                        {discoverTabs.map((d, i) =>
                            d.value.map((v, index) => (
                                <StyledChip
                                    key={`${i}-${index}`}
                                    label={v}
                                    component="a"
                                    variant="outlined"
                                    clickable
                                    onClick={() => handleChipClick(`${i}-${index}`, d.label, v)}
                                    className={activeChip === `${i}-${index}` ? 'Mui-active' : ''}
                                />
                            ))
                        )}
                    </Stack>
                    {activeLabel == 'all' && <SearchBox placeholder="Search Songs " searchValue={searchInput} handleSearchInput={handleSearchInput} />}
                </Box>
                <Grid container spacing={2} alignItems="stretch">
                    {loader ?
                        Array.from(new Array(8)).map((item, index) => (<Grid key={index} item xs={12} md={4} lg={3}>
                            <SkeletonCard />
                        </Grid>))
                        :
                        loader === false && playlistData.length === 0 ? (
                            <NoResults />
                        )
                            :
                            activeLabel !== "trending" ? playlistData.map((data, index) => (
                                <Grid key={index} item xs={12} md={4} lg={3} >
                                    {cardContainer(data)}
                                </Grid>
                            ))
                                :
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="characters" direction="horizontal">
                                        {(provided) => (
                                            <Grid container {...provided.droppableProps} ref={provided.innerRef} >
                                                {playlistData.map((data, index) => (
                                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                                        {(provided) => (
                                                            <Grid item xs={12} md={4} lg={3} paddingLeft={3} paddingTop={3} >
                                                                {cardContainer(data, provided)}
                                                            </Grid>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </Grid>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                    }
                </Grid>
                <PaginationComponent setLimit={setLimit} setPage={setPage} limit={limit} total_count={total_count} page={page} />
                <SnackbarContainer />
            </Box>
        </Box >
    )
} 
