import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import SkeletonCard from '../../_helpers/reusablefunctions/SkeletonCard';
import NoResults from '../../_helpers/reusablefunctions/NoResults';
import {getStrPlatformImg} from '../../_helpers/reusablefunctions/getStreamingIcons';
import playlist from '../../img/Frame 48096170.png'

function PlaylistAddSong({ loader, playlistData, removeSongFromPlayList }) {

  return (
    <Box width={'100%'} >
      {loader ?
        Array.from(new Array(8)).map((item, index) => (<Grid key={index} item xs={12} md={12} lg={12}>
          <SkeletonCard />
        </Grid>))
        :
        loader === false && playlistData.length === 0 ? (
          <NoResults />
        )
          : playlistData?.map(data => (
            <Card key={data.id} className='m-2'>
              <Box display={'flex'} justifyContent={'space-between'} >
                <CardContent className='p-0'>
                  <Box display={'flex'} alignItems={'center'} >
                    <img src={data && data.songPoster ? process.env.REACT_APP_CREATORURL_MEDIA + 'media/' + data.songPoster : playlist} alt="img" style={{ width: '7rem', height: '5rem', borderRadius: '10px', margin: '0.5rem', border: '1px solid #e3e3e3' }} />
                    <Box>
                      <Typography variant="body2">
                        {data?.songTitle}
                      </Typography>
                      {data?.relatedPlatform?.map(platform => (<img key={platform.id} src={getStrPlatformImg(platform.streamingPlatformKey)} style={{ width: '25px', height: '25px', marginRight: '5px' }} />)) || []}
                    </Box>
                  </Box>
                </CardContent>
                <CardActions>
                  <Button variant='text' className='active' onClick={() => removeSongFromPlayList(data.id)} >
                    Remove
                  </Button>
                </CardActions>
              </Box>
            </Card>
          ))}
    </Box>
  );
}

export default PlaylistAddSong;
