import * as React from 'react';
import { TablePagination } from '@mui/material';

export default function PaginationComponent({ setPage, setLimit, ...props }) {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(+event.target.value);
        setPage(0);
    };

    return (
        <TablePagination
            rowsPerPageOptions={[20, 30, 40]}
            component="div"
            count={props.total_count}
            rowsPerPage={props.limit}
            page={props.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
}
