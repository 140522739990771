
export const validateISRC = (isrc) => {
    if (isrc.length !== 12) {
        return {
            isValid: true,
            errorMessage: "ISRC must be exactly 12 characters long."
        };
    }
    const isrcPattern = /^[A-Z]{2}[A-Z0-9]{3}\d{2}\d{5}$/;
    if (!isrcPattern.test(isrc)) {
        return {
            isValid: true,
            errorMessage: "ISRC must be in the format (e.g., USRC17607839)."
        };
    }

    return { isValid: false, errorMessage: "" };
}

export const validateUPC = (code) => { 
    if (!/^\d{12}$|^\d{13}$/.test(code)) {
        return {
            isValid: true,
            errorMessage: "Code must be 12 or 13 digits long."
        };
    }

    const digits = code.split('').map(Number);
    const checkDigit = digits.pop(); // Remove the last digit (check digit)

    let sum = 0;

    // UPC-A (12 digits) or EAN-13 (13 digits) logic
    if (code.length === 12) { // UPC-A
        for (let i = 0; i < digits.length; i++) {
            sum += i % 2 === 0 ? digits[i] * 3 : digits[i]; // Multiply odd positions by 3
        }
    } else if (code.length === 13) { // EAN-13
        for (let i = 0; i < digits.length; i++) {
            sum += i % 2 === 0 ? digits[i] : digits[i] * 3; // Multiply even positions by 3
        }
    }

    const expectedCheckDigit = (10 - (sum % 10)) % 10;

    if (checkDigit !== expectedCheckDigit) {
        return {
            isValid: true,
            errorMessage: "Invalid check digit."
        };
    }

    return { isValid: false, errorMessage: "" }; // No error, valid UPC
};
