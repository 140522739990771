import React ,{useState}from 'react';
import Box from "@mui/material/Box";
import banner6 from '../../img/service2.png';
import banner5 from '../../img/service1.png';
import banner1 from '../../img/pressRelease.png';
import banner4 from '../../img/lyrics.png';
import banner2 from '../../img/blogPlacement.png';
import banner3 from '../../img/artist.png';
import chatbot from '../../img/chatbot.png'
import Rating from '@mui/material/Rating';
import { Link } from 'react-router-dom';
function AddToCart(props) {
    const data = [
                  { id: 3, banner: banner3, title: "Artist Press Kit services", subTitle: "Lorem Ipsum is simply dummy text of the printing " },
                  { id: 5, banner: banner5, title: "Service 1", subTitle: "Lorem Ipsum is simply dummy text of the printing      " },
    ]
    const [value, setValue] = React.useState(3);
    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);

        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p >
                {isReadMore ? text.slice(0, 40) : text}
                <Link to="#" onClick={toggleReadMore} >
                    {isReadMore ? "Show more" : " Show less"}
                </Link>
            </p>
        );
    };
    return (
        <Box
            sx={{ width: 380 }}
            role="presentation"
        >
            <div className="row p-3">
                <div>
                    <h6 className="fw-bold">Added to cart</h6>
                    {/* <span>{"&#10799"}</span> */}
                </div>
                <div>
                    <span>Cart subtotle(1 item) : <span className="text-danger">$ 50.00</span></span>
                    <button className="btn cardBtn py-1 rounded-pill float-end">Remove</button>
                </div>
                <div className="pt-3">
                    <button className="btn cardBtn rounded-pill">Wishlist</button>
                   <Link to="/shopping-cart"><button className="btn cardBtn rounded-pill float-end shadow">Proceed to checkout (1 item)</button></Link> 
                </div>
                <div className="paymentCart mt-4 p-4">
                    <span> you may want to add more item in your cart which can enhance your public image</span>
                    {data.map((item) =>
                        <div className=" mb-4 ">
                            <div className="card border rounded ">
                                <img src={item.banner} className="w-100" />
                                <div className="card-body">
                                    <div>
                                        <h5>{item.title}</h5>
                                        <ReadMore>{item.subTitle}</ReadMore>
                                        {/* <span>{item.subTitle}</span>
                                        <Link to="/services-plan">See More</Link> */}
                                        <hr className="m-1" />
                                        <div className='pt-2'>
                                            <Rating name="read-only" value={value} readOnly />
                                            <Link to="/artists"><span className="float-end"> Artist</span></Link>
                                        </div>
                                        <div className='pt-3'>
                                            <span>$50.00</span>
                                            <button className="btn btn-outline-danger fs-6 text-bold py-1 rounded-pill float-end" > Add to cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Box>
    );
}

export default AddToCart;