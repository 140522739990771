import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { resetPasswordApi } from "../_constants/api.constant";
export async function resetPassword(postData, token){
    const response=await axios.post(
        resetPasswordApi.RESET+token+'/',
       postData,
        /* {
            headers:{
                Authorization: 'Token '+token,
            },
        } */
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}