import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Dashboard from '../dashboard/dashboard';
import Footer from '../../admin/footer';
import {Visibility} from "@mui/icons-material"
import {Close} from "@mui/icons-material"
import { Link } from 'react-router-dom';
function TrackTicket(props) {
    const history = useHistory();

    function back() {
        history.push("/helpdesk")
    }
    return (
        <div>
            {/* <Dashboard> */}
                <div className="container-fluid p-0">
                    <div className="row">
                        <div onClick={back}>
                            <span className="h3"> &#8592;</span><span className="h6"> Back</span>
                        </div>
                        <div className="pt-4 p-4" >
                            <h4> Track your Ticket</h4>
                            <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                        </div>
                        <div className="border rounded">
                            <table className="table p-0 m-0">
                               
                                <tbody className="p-0 m-0">
                                    <tr className=" border-bottom bg-light">
                                        <td  className="p-3"></td>
                                        <td  className="p-3">Query Description</td>
                                        <td  className="p-3">Action</td>
                                    </tr>
                                    <tr>
                                        <td className="p-3">Can I get the detail of my PF account?</td>
                                        <td className="p-3">Seriously guys i need it,please let me know as soon as you can provide.</td>
                                        <td className="p-3">
                                        <div><Link to="issue"> <Visibility/></Link><Close/></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-3">Can I get the detail of my PF account?</td>
                                        <td className="p-3">Seriously guys i need it,please let me know as soon as you can provide.</td>
                                        <td className="p-3">
                                        <div><Link to="issue"> <Visibility/></Link><Close/></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-3">Can I get the detail of my PF account?</td>
                                        <td className="p-3">Seriously guys i need it,please let me know as soon as you can provide.</td>
                                        <td className="p-3">
                                            <div><Link to="issue"> <Visibility/></Link><Close/></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>



                    </div>

                </div>
            {/* </Dashboard>
            <Footer /> */}
        </div>
    );
}

export default TrackTicket;