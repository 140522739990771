import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Stack, Typography, Tooltip, IconButton, Modal, Checkbox, Skeleton, Dialog, TablePagination, TextField, InputAdornment } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ApiService from '../../_services/ApiService';
import playlist from '../../img/Frame 48096170.png'
import { useDispatch } from 'react-redux';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import PaginationComponent from '../../_helpers/reusablefunctions/paginationComponent';
import SearchIcon from '@mui/icons-material/Search';
const checkboxStyle = {
    backgroundColor: '#fff',
    padding: '4px',
};
export default function AddSong({ openSongModal, setOpenSongModal, playlistSongData, getSingleSong }) {
    const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
    const location = useLocation()
    const dispatch = useDispatch();
    const playlistData = location?.state?.playlistData
    const [checkedCount, setCheckedCount] = useState(0);
    const [selectedSongIds, setSelectedSongIds] = useState([])
    const [loadingAddSonglist, setLoadingAddSonglist] = useState(false)
    const [songList, setSongList] = useState([])
    const [isChecked, setIsChecked] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const [total_count, setTotal_count] = useState(0)
    const [searchInput, setSearchInput] = useState('')

    const allSongList = () => {
        ApiService.get('allSongs', { limit: limit, offset: page }).then(res => {
            if (res.status == '200') {
                setSongList(res?.data?.results)
                setTotal_count(res?.data?.total_count)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        allSongList()
    }, [page, limit])


    const handleCheckboxChange = (isChecked, selectedSongId) => {
        setCheckedCount((prevCount) => (isChecked ? prevCount + 1 : prevCount - 1));

        setSelectedSongIds((prevSelectedSongIds) => {
            if (isChecked) {
                return [...prevSelectedSongIds, selectedSongId];
            } else {
                return prevSelectedSongIds.filter((id) => id !== selectedSongId);
            }
        });
    };

    const removeAllChecked = () => {
        setCheckedCount(0)
        setSelectedSongIds([])
        setIsChecked(false)
    }

    const handleCheckbox = (selectedSongId) => {
        setIsChecked((prevCheckedState) => ({
            ...prevCheckedState,
            [selectedSongId]: !prevCheckedState[selectedSongId],
        }));

        handleCheckboxChange(!isChecked[selectedSongId], selectedSongId);
    };

    function apiCall(dataObj, value) {
        ApiService.put('createEditPlaylist', dataObj, { id: playlistData?.playlist_id }).then(function (response) {
            if (response.status === 200 || response.status === 201) {
                getSingleSong()
                removeAllChecked()
                setOpenSongModal(false)
                //value == 'success ? successSnack(formSnackbar.playlist.playlistUpdate):'
            }
        })
            .catch(function (error) {
                errorSnack(formSnackbar.errors.errorMsg)
            })
    }

    const unmatchedData = () => {
        let songData = selectedSongIds.filter(item1 => !playlistSongData.some(item2 => item2.id === item1));
        const matchedSongs = playlistSongData.filter(song =>
            selectedSongIds.includes(song.id)
        );
        const matchedSongNames = matchedSongs.map(song => song.songTitle);
        return { songData, matchedSongNames };
    }

    const addSongToPlaylist = () => {
        const unmatchedSongs = unmatchedData();
        const dataObj = { playlist_song: unmatchedSongs?.songData.join(',') }
        if (unmatchedSongs?.songData?.length > 0 && unmatchedSongs?.matchedSongNames?.length == 0) {
            infoSnack("Adding song to the playlist.")
            apiCall(dataObj, 'success')
        } else if (unmatchedSongs?.songData?.length > 0 && unmatchedSongs?.matchedSongNames?.length > 0) {
            warningSnack(<>
                These songs already exist in this playlist.
                Adding new selected song  <strong>{unmatchedSongs?.matchedSongNames?.join(', ')} </strong>
                to this playlist.
            </>)
            apiCall(dataObj, 'warning')
        } else {
            warningSnack(<>
                All selected songs already exist in this playlist..
            </>)
        }

    }

    const searchSongHandler = (e) => {
        const { value } = e.target
        if (value) {
            setSearchInput(value)
        } else {
            allSongList()
        }
    }
    const getSearchSongPlaylist = (value) => {
        ApiService.get('searchSongPlaylist', { 'search': value, limit: limit, offset: page }).then(function (response) {
            console.log(response)
            if (response.status === 200) {
                setSongList(response.data?.results)
                setTotal_count(response?.data?.total_count)
                setPage(page);
            }
            else {
                // errorSnack(formSnackbar.errors.imgUploadSize);
            }
        }).catch(function (error) {
            // dispatch(setLoader(false))
            errorSnack(formSnackbar.errors.errorMsg);

            return error.response;
        })
    }

    useEffect(
        function () {
            if (searchInput) {
                console.log(searchInput)
                let delaySearch;
                delaySearch = setTimeout(() => {
                    getSearchSongPlaylist(searchInput)
                }, 1000);
                return () => clearTimeout(delaySearch);
            } else {
            }
        }, [searchInput])

    function closeSongMenu(params) {
        removeAllChecked()
        setOpenSongModal(false)
    }

    return (
        <Dialog open={openSongModal} fullWidth>
            <Box className='p-3' >
                <Box className='d-flex justify-content-between'>
                    <Box>
                        <h4> Add song to playlist</h4>
                    </Box>
                    <Box>
                        <IconButton aria-label="delete" onClick={() => closeSongMenu()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box style={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    border: '2px solid #fff',
                    borderRadius: '4px',
                    padding: '10px',
                }}>
                    <TextField
                        id="filled-search"
                        label="Search Song"
                        type="search"
                        variant="outlined"
                        className='w-100'
                        onChange={searchSongHandler}
                        slotprops={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                    {!loadingAddSonglist ? songList && songList.length > 0 ? songList.map(item => {
                        return (
                            <Box key={item.id}>
                                <Box className="col-md-12 my-2 col-sm-12 col-12 rounded-3 border shadow-sm bg-white">
                                    <Box style={{ position: 'relative', width: '100%' }} className='d-flex justify-content-between'>
                                        <Box>
                                            <img src={item && item.songPoster ? process.env.REACT_APP_CREATORURL_MEDIA + 'media/' + item.songPoster : playlist} alt="img" style={{ width: '7rem', height: '5rem', borderRadius: '10px', padding: '0.5rem' }} />
                                            <Box style={{ position: 'absolute', top: 0, left: 0 }}>
                                                <Checkbox checked={isChecked[item.id] || false} onChange={() => handleCheckbox(item.id)} inputProps={{ 'aria-label': 'Checkbox demo' }} style={checkboxStyle} />
                                            </Box>
                                        </Box>
                                        <Box style={{ paddingTop: '0.5rem', width: '100%', margin: 'auto' }}>
                                            <Box title={item.songTitle} style={{ fontSize: '12px', wordWrap: 'break-word', textTransform: 'uppercase', fontWeight: 'bold' }}>
                                                {item?.songTitle || '--'}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    }) : null : <Skeleton height={100} />}
                </Box>

                <Box style={{ marginTop: '20px' }}>
                    {checkedCount > 0 && (
                        <Box>
                            <IconButton onClick={() => removeAllChecked()} aria-label="delete">
                                <CloseIcon />
                            </IconButton> {checkedCount} Selected
                        </Box>
                    )}
                    <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {songList && songList.length > 0 &&
                            <Button className='gn-actionbtn' onClick={addSongToPlaylist} disabled={selectedSongIds.length == 0}>
                                Add
                            </Button>}
                    </Box>
                </Box>
                <PaginationComponent setLimit={setLimit} setPage={setPage} limit={limit} total_count={total_count} page={page} />
            </Box>
        </Dialog>
    )
} 
