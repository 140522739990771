import {
    FETCH_RELEASE_ALBUM_FAILURE,
    FETCH_RELEASE_ALBUM_REQUEST,
    FETCH_RELEASE_ALBUM_SUCCESS
} from "./releaseAlbumDataTypes";
const initialState = {
    loading: false,
    releaseAlbumData: {},
    // selectedQuestionList: null,
    error: '',
  }
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case   FETCH_RELEASE_ALBUM_REQUEST:
        return {
          ...state,
          loading: true
        }
      case FETCH_RELEASE_ALBUM_SUCCESS:
        return {
          ...state,
          loading: false,
          releaseAlbumData: action.payload,
          error: ''
        }
      case FETCH_RELEASE_ALBUM_FAILURE:
        return {
          ...state,
          loading: false,
          releaseAlbumData: {},
          error: action.payload
        }
       
      default: return state
    }
  }
  
  export default reducer
  