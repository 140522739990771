import React from 'react'

const NoResults = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
        <h1>No Results Found</h1>
    </div>
  )
}

export default NoResults