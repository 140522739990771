import React from 'react'
import Dashboard from '../dashboard/dashboard'
import Button from '@mui/material/Button';

function Subscription() {
  return (
            <div>
                <div className="bg-light">
                    {/* <Dashboard> */}
                        <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card cardtop">
                                    <div className="card-body text-center">
                                        <h5 className="font pt-3">BASIC</h5>
                                        <p className="pt-2 opacity-50">Get published on 6 leading
                                            <br/>
                                         news websites.</p>
                                         <p className="pt-2 opacity-50">Get yourself recognized globally 
                                            <br/>
                                            with the high DA websites. </p>
                                        <p className="pt-2 opacity-50">Increase your online visibility by
                                            <br/>
                                            being promoted on professional
                                            <br/>
                                            press release.</p>

                                            <h5 className="font text-danger pt-3">$199
                                                <span className="text-black fonts opacity-50">
                                                /month
                                                </span>
                                                <p className="text-black fonts opacity-50 text-decoration-line-through">299/month 34% off</p>
                                            </h5>
                                            <Button variant="contained" className="bg-danger rounded-pill mt-3 py-2">
                                            Buy Now
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card cardtop">
                                    <div className="card-body text-center">
                                        <h5 className="font pt-3">INTERMEDIATE</h5>
                                        <p className="pt-2 opacity-50">Get published on 10 leading news
                                            <br/>
                                            websites with high Domain Authority.</p>
                                         <p className="pt-2 opacity-50">Get featured on our social media 
                                            <br/>
                                            handles and website. </p>
                                        <p className="pt-2 opacity-50">Increase your reach and gather views 
                                            <br/>
                                            on your song by being on the top
                                            <br/>
                                             music blogs and websites.	</p>

                                            <h5 className="font text-danger pt-3">$199
                                                <span className="text-black fonts opacity-50">
                                                /month
                                                </span>
                                                <p className="text-black fonts opacity-50 text-decoration-line-through">299/month 34% off</p>
                                            </h5>
                                            <Button variant="contained" className="bg-danger rounded-pill mt-3 py-2">
                                            Buy Now
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card cardtop">
                                    <div className="card-body text-center">
                                        <h5 className="font pt-3">BASIC</h5>
                                        <p className="pt-2 opacity-50">Get published on 6 leading
                                            <br/>
                                         news websites.</p>
                                         <p className="pt-2 opacity-50">Get yourself recognized globally 
                                            <br/>
                                            with the high DA websites. </p>
                                        <p className="pt-2 opacity-50">Increase your online visibility by
                                            <br/>
                                            being promoted on professional
                                            <br/>
                                            press release.</p>

                                            <h5 className="font text-danger pt-3">$199
                                                <span className="text-black fonts opacity-50">
                                                /month
                                                </span>
                                                <p className="text-black fonts opacity-50 text-decoration-line-through">299/month 34% off</p>
                                            </h5>
                                            <Button variant="contained" className="bg-danger rounded-pill mt-3 py-2">
                                            Buy Now
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    {/* </Dashboard> */}
                </div>
            </div>
  )
}

export default Subscription