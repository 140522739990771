import axios from "axios";
import { authApi } from "../_constants/api.constant"
import {setUserId} from '../../_helpers/auth/secureToken'
export async function login(data) {
  const response = await axios.post(
    authApi.LOGIN,
    data,
  ).then(function (response) {
    //localStorage.setItem("userId", response.data.data[0].id);
    setUserId(response.data.data[0].id)
    return response;
  }).catch(function (error) {

    if (error.response) {
   
      return (error.response)

    }
    else return (error);

  });


  return response;
}