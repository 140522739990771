import React ,{useState}from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
function Placement(props) {
    let history=useHistory()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      };
    
    
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

//     if(window.location.pathname === "/placement")
//   handleOpen()
       
    return (
        <div>
              <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
         Comming Soon 
          </Typography>
         
        </Box>
      </Modal>
        </div>
    );
}

export default Placement;