import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, Card, CardContent, CardMedia, IconButton, Link, Modal, Skeleton, TextField, Typography, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { getStrPlatformImg } from '../../_helpers/reusablefunctions/getStreamingIcons';
import ApiService from '../../_services/ApiService';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import imgThumbnail from '../../img/Frame 48096170.png'
import { useLocation } from 'react-router-dom';

function PlaylistPlatforms({ allPlatfromList, getPlaylistData, playlistSongLibrary, allplaylistSongLibrary, setPlaylistSongLibrary, getSingleSong, index }) {
  const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
  const location = useLocation()
  const playlistData = location?.state?.playlistData
  const userData = useSelector((state) => state.userData.userData);
  const [selectedStatus, setSelectedStatus] = useState(true);
  const [streamingLink, setStreamingLink] = useState()
  const [selectedPlatform, setSelectedPlatform] = useState()
  const [platformLogo, setPlatformLogo] = useState(imgThumbnail)
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const removeMatchedPlatfrom = (action, value) => {

  }

  useEffect(() => {
    if (playlistSongLibrary) {
      // Don't modify props directly
      removeMatchedPlatfrom('remove')
      setSelectedStatus(playlistSongLibrary?.status ?? true)
      setStreamingLink(playlistSongLibrary?.streamingPlatformlink ?? '')
      setSelectedPlatform(playlistSongLibrary?.streamingPlatformName ? {
        platformName: playlistSongLibrary.streamingPlatformName,
        platformId: playlistSongLibrary.id
      } : null)
      setPlatformLogo(playlistSongLibrary?.streamingPlatformKey ?
        getStrPlatformImg(playlistSongLibrary.streamingPlatformKey) :
        imgThumbnail)
    }
  }, [playlistSongLibrary])

  const formatPlatformName = (name) => {
    if (!name) return '';
    // Split the name into words
    return name.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const createRelatedPlatformLink = (postObj) => {
    postObj.playlistId = playlistData?.playlist_id;
    if (postObj) {
      infoSnack(formSnackbar.relatedPlatform.realtedPlatformCreate)
      ApiService.post('platformCreateUpdateDelte', postObj).then((response) => {
        if (response.status === 200 || response.status === 201) {
          getSingleSong()
          successSnack(formSnackbar.relatedPlatform.realtedPlatformCreateSuccess)
        }
      }).catch(error => {
        console.log(error)
        errorSnack(formSnackbar.errors.errorMsg)
      })
    }
  }

  function updateRelatedPlatformLink(postObj) {
    postObj.playlistId = playlistData?.playlist_id;
    if (playlistSongLibrary?.streamingPlatformlink !== postObj?.streamingPlatformlink) {
      infoSnack(formSnackbar.relatedPlatform.realtedPlatformUpdate)
      ApiService.put('platformCreateUpdateDelte', postObj, { id: playlistSongLibrary?.id }).then((response) => {
        if (response.status === 200 || response.status === 201) {
          getSingleSong()
          successSnack(formSnackbar.relatedPlatform.realtedPlatformUpdateSuccess)
        }
      }).catch(error => {
        console.log(error)
        errorSnack(formSnackbar.errors.errorMsg)
      })
    }
  }

  const platformStatusChangeHandler = (e, platformId) => {
    setSelectedStatus(e.target.checked);
    userData.id && String(platformId).startsWith('temp')
      ? createRelatedPlatformLink({
        status: e.target.checked,
      })
      : updateRelatedPlatformLink({
        status: e.target.checked,
      });
  };

  const platformChangeHandler = (e, newValue, platformId) => {
    e.preventDefault();
    if (newValue) {
      let newplatformLogo = getStrPlatformImg(newValue?.platform_key);
      setPlatformLogo(newplatformLogo)
      removeMatchedPlatfrom('update', newValue)
      // userData.id && platformId == undefined
      userData.id && String(platformId).startsWith('temp')
        ? createRelatedPlatformLink({
          streamingPlatform: newValue?.platformId,
        })
        : updateRelatedPlatformLink({
          streamingPlatform: newValue?.platformId,
        });
      setSelectedPlatform(newValue);
    }
  };

  const isValidURL = (url) => {
    const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
    return regex.test(url);
  };

  const playlistPlatformChangeHandler = (e, data) => {
    e.preventDefault();
    const { value } = e.target;
    setStreamingLink(value);
    if (e.type === 'blur' && value) {
      if (!isValidURL(value)) {
        setError(true);
        setErrorMessage("Please enter a valid URL");
        return;
      }
      const selectedPlatformDomain = selectedPlatform?.streamingPlatformKey; // Assume `platformDomain` exists in selectedPlatform
      if (selectedPlatformDomain && !value.includes(selectedPlatformDomain)) {
        setError(true);
        setErrorMessage(`URL must belong to ${selectedPlatform.platformName}`);
        return;
      }
      setError(false);
      setErrorMessage('')
      userData.id && data.id == undefined
        ? createRelatedPlatformLink({
          streamingPlatformlink: value
        })
        : updateRelatedPlatformLink({
          streamingPlatformlink: value
        });
    }
  };

  const deletePlatformHanlder = (id) => {
    if (id) {
      infoSnack(formSnackbar.relatedPlatform.relatedPlatformDelete)
      ApiService.delete('platformCreateUpdateDelte', { id: id }).then((response) => {
        successSnack(formSnackbar.relatedPlatform.relatedPlatformDeleteSuccess)
        const deleteId = allplaylistSongLibrary.filter(d => d.id !== id)
        setPlaylistSongLibrary(deleteId)
      }).catch(error => {
        errorSnack(formSnackbar.errors.errorMsg)
        console.log(error)
      })
    }
    else {
      const deletePlatform = allplaylistSongLibrary.filter((_, i) => i !== index);
      setPlaylistSongLibrary(deletePlatform)
    }
  }

  return (
    <Box width={'100%'} >
      {getPlaylistData?.type_official == 'official' ? <Card className='m-2'>
        <CardContent className='p-2'>
          <Box display={'flex'} alignItems={'center'} gap={2} >
            <CardMedia
              component="img"
              sx={{ width: '50px', height: '50px', borderRadius: '100%' }}
              image={platformLogo}
            />
            <Box width={'90%'} >
              <Autocomplete
                options={allPlatfromList}
                getOptionLabel={(option) => formatPlatformName(option.platformName) || ''}
                id="controlled-demo"
                value={selectedPlatform || null}
                onChange={(e, value) => platformChangeHandler(e, value, playlistSongLibrary?.id)}
                size="small"
                sx={{ padding: '5px' }}
                renderOption={(props, option) => (
                  <li {...props}>
                    {formatPlatformName(option.platformName)}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params}
                    sx={{
                      '&>div': {
                        paddingRight: '0px !important'
                      }
                    }}
                    placeholder="Add platform"
                    variant="standard"
                    color="secondary"
                  />
                )}
              />
              <TextField id="platforms-id"
                onChange={(e) => playlistPlatformChangeHandler(e, playlistSongLibrary)}
                onBlur={(e) => playlistPlatformChangeHandler(e, playlistSongLibrary)}
                className='w-100'
                label={playlistSongLibrary?.streamingPlatformName}
                value={streamingLink}
                variant="filled"
                error={error}
                helperText={error ? errorMessage : ""}
                size="small" />
            </Box>
            <Box className='d-flex flex-column gap-4 align-items-end'>
              <label className="switch">
                <input
                  checked={selectedStatus}
                  type="checkbox"
                  id={playlistSongLibrary?.id}
                  role="switch"
                  onChange={(e) => platformStatusChangeHandler(e, playlistSongLibrary?.id)}
                />
                <span className="slider round" style={{ opacity: (selectedStatus === false && streamingLink === '') ? 0.3 : 1 }} title={selectedStatus === true ? "Status True" : "Status False"}></span>
              </label>
              <IconButton onClick={() => deletePlatformHanlder(playlistSongLibrary?.id)}>
                <DeleteIcon sx={{ color: '#6c757d' }} />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
        :
        <Link href={playlistSongLibrary?.streamingPlatformlink}>
          <Card className='m-2'>
            <CardContent className='p-2'>
              <Box display={'flex'} alignItems={'center'} gap={2} >
                <CardMedia
                  component="img"
                  sx={{ width: '50px', height: '50px', borderRadius: '100%' }}
                  image={platformLogo}
                />
                <Box width={'90%'} >
                  <Typography>{playlistSongLibrary?.streamingPlatformName}</Typography>
                  {/* <TextField id="platforms-id"
                className='w-100'
                value={streamingLink}
                variant="filled"
                disabled
                size="small" /> */}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Link>}
    </Box>
  );
}

export default PlaylistPlatforms;
