import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import cart1 from '../../img/cartList1.png'
import { Search } from '@mui/icons-material';
import { Link } from '@mui/material';
function ShoppingCart(props) {
    const history = useHistory()
    function back() {
        history.push("/services")
    }
    return (
        <div className="bg-light mb-5">
            <div className="container-fluid p-0">
                <div className="row">
                    <div onClick={back}>
                        <span className="h3"> &#8592;</span><span className="h6"> Back</span>
                    </div>
                    <div className="mt-4 mb-3">
                        <div className="input-group Btn">
                            <input className="form-control rounded-pill searchBtn border-2" value="search" id="example-search-input" />
                            <span className="input-group-append">
                                <button className="btn btn-outline-secondary  border-0 ms-n5" type="button">
                                    <Search />
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-8  col-sm-12 col-12">
                            <h4 className='pb-3 pt-sm-3'>Shopping Cart</h4>
                            <div className="card border shadow">
                                <div className="card-body">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col"><h5>Product Detail</h5></th>
                                                <th scope="col " className="float-end"><h5>Price</h5></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className=''>
                                                <th scope="row">
                                                    <div className="d-flex">
                                                        <img src={cart1} />
                                                        <div className="px-3">
                                                            <label>Writing Artists Bio</label><br className="pb-3" />
                                                            <span className="text-decoration-line-through">$200.00</span>
                                                        </div>
                                                    </div></th>
                                                <td><h5 className='float-end'>$50.00</h5></td>
                                                <td><button className="btn btn-outline-danger rounded-pill px-5 float-end">Remove</button></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <div className="d-flex">
                                                        <img src={cart1} />
                                                        <div className="px-3">
                                                            <label>Writing Artists Bio</label><br className="pb-3" />
                                                            <span className="text-decoration-line-through">$200.00</span>
                                                        </div>
                                                    </div></th>
                                                <td><h5 className='float-end'>$50.00</h5></td>
                                                <td><button className="btn btn-outline-danger rounded-pill px-5 float-end">Remove</button></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <div className="d-flex">
                                                        <img src={cart1} />
                                                        <div className="px-3">
                                                            <label>Writing Artists Bio</label><br className="pb-3" />
                                                            <span className="text-decoration-line-through">$200.00</span>
                                                        </div>
                                                    </div></th>
                                                <td><h5 className='float-end'>$50.00</h5></td>
                                                <td><button className="btn btn-outline-danger rounded-pill px-5 float-end">Remove</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4  col-sm-12 col-12">
                            <h4 className='pb-3 mt-3'>Order Summary</h4>
                            <div className="card border shadow rounded ">
                                <div className="card-body orderSummary">
                                    <h5 className='p-2'> Writing Artist Bio</h5>
                                    <label className="p-2">  PROMO CODE</label>
                                    {/* <div className="py-3">
                                        <input type="text" placeholder="Enter your Code" className="p-3 border w-100 rounded" />
                                    </div> */}
                                    <div className="input-group rounded">
                                        <input className="form-control  rounded rounded-3 border p-3" placeholder="Enter your Code" id="example-search-input" />
                                        <span className="input-group-append  ">
                                            <Link className="border-0 position-absolute apply my-3 text-decoration-none text-danger" >
                                            Apply
                                            </Link>
                                        </span>
                                    </div>
                                    <hr />
                                    <div className="d-flex justify-content-between py-2">
                                        <h6>TOTAL COST</h6><label className="float-end h5" >$150</label>
                                    </div>
                                    <div className="text-center">
                                        <button className="btn btn-danger rounded-pill "> Check out</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            {/* </Dashboard>
            <Footer /> */}
        </div>
    );
}

export default ShoppingCart;