import axios from "axios";
import {registerApi} from '../_constants/api.constant'
export async function RegisterUser(postData){
    const response=await axios.post(
        registerApi.REGISTER,
        postData,
                {
                    // headers:{
                    //     Authorization: 'Bearer '+getSelfToken(),
                    // },
                }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        console.log(error.response);
        return error.response;
        });
    return response;
}