import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Stack, Typography, Tooltip, IconButton, Tab, styled, Link, List, ListItem, ListItemText } from '@mui/material';
import playlist from '../../img/Frame 48096170.png'
import { useLocation, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getStrPlatformImg } from '../../_helpers/reusablefunctions/getStreamingIcons';
import NoResults from '../../_helpers/reusablefunctions/NoResults';
import ReusableTab from '../../_helpers/reusablefunctions/ReusableTab';
const PlaylistCard = styled(Card)(({ theme }) => ({
    margin: theme.spacing(2),
    borderRadius: '10px',
    boxShadow: ' 0px 4px 4px 0px rgba(0, 0, 0, 0.15), 0px 8px 12px 6px rgba(0, 0, 0, 0.08)',
    border: '1px solid #eae7e7',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
}));
const accountTabs = [
    { tabTitle: 'Platform', tabActiveId: '1' },
    { tabTitle: 'Promotion Detail', tabActiveId: '2' },
];
export default function MusicPlatforms() {
    const location = useLocation();
    const history = useHistory();
    const [tabValue, setTabValue] = useState('1');
    const musicPlatformData = location?.state?.musicPlatformData;
    const reviewList = [
        { id: 1, name: 'Platforms', label: musicPlatformData?.promotion_details?.platforms?.map(item=> item).join(',') },
        { id: 2, name: 'Artist Count', label: musicPlatformData?.promotion_details?.number_of_artists },
        { id: 3, name: 'Amount', label: musicPlatformData?.promotion_details?.budget_amount+'Rs/-' },
        { id: 4, name: 'Per person budget', label: musicPlatformData?.promotion_details?.per_person_budget+'Rs/-' },
    ]

    const handleRouteBack = () => {
        history.push('/discover-music')
    }
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const musicPlatform = () => {
        return (
            <Box>
                {musicPlatformData?.relatedPlatform.length ? musicPlatformData?.relatedPlatform
                    .map((card, index) => (
                        card?.streamingSonglink && card?.status ? <Box key={card.id} index={index}>
                            <PlaylistCard sx={{ padding: '10px' }} >
                                <Link href={card?.streamingSonglink ? `${[card.streamingSonglink]}` : '#'} underline='none' passHref>
                                    <Box className='d-flex align-items-center'>
                                        <Box className="link-thumbnail d-flex align-items-center px-2">
                                            <img
                                                alt="link Image"
                                                src={card.streamingPlatformKey ? getStrPlatformImg(card.streamingPlatformKey) : '/images/sparkleTheme/imgThumbnail.png'}
                                                width={40}
                                                height={40}
                                                className="rounded-circle "
                                            />
                                        </Box>
                                        <Typography variant="subtitle1" fontWeight="bold" sx={{ color: '#e02221' }} >
                                            {card.streamingPlatformName}
                                        </Typography>
                                    </Box>
                                </Link>
                            </PlaylistCard>
                        </Box> : null
                    )) : <NoResults />}
            </Box>
        )
    }

    const musicCampaigndetail = () => {
        return (
            <Box>
                <PlaylistCard sx={{ padding: '10px' }} >
                    <Box className='d-flex align-items-center'>
                        <Box className="link-thumbnail d-flex align-items-center px-2">
                            {/* <Box>
                                <Button></Button>
                            </Box> */}
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {reviewList.map((data, index) => (
                                    <ListItem key={index} alignItems="flex-start" sx={{ paddingTop: '0px', alignItems: 'center' }}>
                                        {/* {data?.img && <ListItemAvatar sx={{ width: '80px', height: '80px', marginRight: '10px' }}>
                                            <img alt={data?.name || '-'} src={data?.img} className='w-100 rounded-1' />
                                        </ListItemAvatar>} */}
                                        <ListItemText
                                            primary={data?.name || '-'}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        sx={{ color: 'text.primary', display: 'inline' }}
                                                    >
                                                    </Typography>
                                                    {data?.label || '-'}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>))}
                            </List>
                        </Box>
                    </Box>
                </PlaylistCard>
            </Box>
        )
    }

    const panels = [
        { value: '1', content: musicPlatform() },
        { value: '2', content: musicCampaigndetail() },
    ];

    return (
        <Box sx={{ padding: '1rem 1rem 3rem 1rem' }}>
            <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} >
                <Button className='gap-3 text-black' onClick={handleRouteBack}><ArrowBackIcon /> {musicPlatformData?.songTitle}</Button>
            </Box>
            <Box mt={2} mb={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={3} >
                        <Card className="" role="box" tabIndex="0">
                            <CardMedia
                                component="img"
                                style={{
                                    height: '200px',
                                }}
                                image={musicPlatformData?.songPoster ? process.env.REACT_APP_CREATORURL_MEDIA + 'media/' + musicPlatformData?.songPoster : playlist}
                            />
                            <CardContent >
                                <Typography variant="body2"><strong>Name:</strong> {musicPlatformData?.songTitle}</Typography>
                                <Box>
                                    <Box className='d-flex align-items-center gap-2' >
                                        <Typography variant="body2"><strong>Genre:</strong></Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                            {musicPlatformData?.genre ?
                                                <Chip key={musicPlatformData?.genre?.id} label={musicPlatformData?.genre?.genre} sx={{ backgroundColor: '#E6EEEF' }} />
                                                : '-'}
                                        </Box>
                                    </Box>
                                    <Box className='d-flex align-items-center gap-2' >
                                        <Typography variant="body2"><strong>Mood:</strong></Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                            {musicPlatformData?.mood ?
                                                <Chip key={musicPlatformData?.mood?.id} label={musicPlatformData?.mood?.mood} sx={{ backgroundColor: '#E6EEEF' }} />
                                                : '-'}
                                        </Box>
                                    </Box>
                                    <Box className='d-flex align-items-center gap-2' >
                                        <Typography variant="body2"><strong>Language:</strong></Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                            {musicPlatformData?.language ?
                                                <Chip key={musicPlatformData?.language?.id} label={musicPlatformData?.language?.language} sx={{ backgroundColor: '#E6EEEF' }} />
                                                : '-'}
                                        </Box>
                                    </Box>
                                </Box>

                            </CardContent >
                        </Card >
                    </Grid>

                    <Grid item xs={12} md={8} lg={9} >
                        {musicPlatformData?.promotion ?
                            <ReusableTab
                                tabValue={tabValue}
                                handleTabChange={handleTabChange}
                                accountTabs={accountTabs}
                                panels={panels}
                            /> :
                            musicPlatform()
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
} 
