import spotify from '../../img/streaming-platform-images/spotify.svg';
import JioSaavn from '../../img/streaming-platform-images/jio-saavn.svg';
import Wynk from '../../img/streaming-platform-images/wynk.svg';
import AmzazonMusic from '../../img/streaming-platform-images/amazon-music.svg';
import Gaana from '../../img/streaming-platform-images/gaana.svg';
import AppleMusic from '../../img/streaming-platform-images/apple-music.svg';
import Hungama from '../../img/streaming-platform-images/hungama.svg';
import YtMusic from '../../img/streaming-platform-images/yt-music.svg';
import Instagrammakereels from '../../img/streaming-platform-images/instagram_1.svg';
import YouTube from '../../img/streaming-platform-images/youtube.png';
import Deezer from '../../img/streaming-platform-images/Deezer.svg';
import imgThumbnail from '../../img/thumbnail.png';

export const getStrPlatformImg = (platform_key) => {
    switch (platform_key) {
        case 'jiosaavn':
            return JioSaavn;
        case 'wynk':
            return Wynk;
        case 'spotify':
            return spotify;
        case 'hungama':
            return Hungama;
        case 'gaana':
            return Gaana;
        case 'amazonmusic':
            return AmzazonMusic;
        case 'applemusic':
            return AppleMusic;
        case 'youtube':
            return YouTube;
        case 'youtubemusic':
            return YtMusic;
        case 'Instagrammakereels':
            return Instagrammakereels;
        case 'deezer':
            return Deezer;

        default:
            return imgThumbnail;
    }
};
