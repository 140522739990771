import React, { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery'
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';
import { getDateAsPerFormat, getTime } from '../../_helpers/reusablefunctions/dateSetter';
import { Tooltip, Zoom, tooltipClasses } from '@mui/material';
import imgPlaceHolder from '../../img/default image.png'
import { useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getSingleAlbum } from '../../_services/album/getSingleAlbum';
import { getSongListOfUser } from '../../_services/album/getSongListofUser';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getPlatformList } from '../../_services/album/getPlatformList.service';
import { getPlanList } from '../../_services/album/getPlanList.service';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UpdateAlbumApprover } from '../../_services/songApprover/updateAlbumApprover';
import { GetApp, Label } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import ApiService from '../../_services/ApiService';
import distribution from '../../img/releaseAlbumSong/distribution.svg'
import CampaignIcon from '@mui/icons-material/Campaign';
import AlbumSongMetaDialog from './AlbumSongMetaDialog'
import { regexValidator } from '../../_helpers/reusablefunctions/regexValidator'
import SnackbarContainer from '../Snackbar';
function ReleaseFinalReview({ }) {
  const smScreen = useMediaQuery('(max-width:768px)');
  const history = useHistory();
  const [showFullLyrics, setShowFullLyrics] = useState(false);
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch()
  const navigate = useHistory();
  const [albumData, setAlbumData] = useState([]);
  const [albumSongLibrary, setAlbumSongLibrary] = useState([])
  const [platformList, setPlatformList] = useState([])
  const { albumId } = useParams();
  const [planList, setPlanList] = useState([])
  const [buttonText, setButtonText] = useState('Lock-in');
  const [isApproved, setIsApproved] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopover, setOpenPopoveP] = React.useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [albumsongData, setAlbumSongData] = useState('');

  const [errors, setErrors] = useState({
    isrc_code: false,
    isrc_code_helperTxt: false,
  })
  const [isrcCode, setIsrcCode] = useState('')
  const handleClick = (e, id) => {
    setAnchorEl(e.currentTarget);
    setOpenPopoveP(id)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      maxWidth: '13.9rem',
    },
  }));
  const gstPercentage = 18;
  const ViewSongMeta = (e, song) => {
    navigate.push('/submitsong/' + song.song_id + "/" + albumId)
  }

  useEffect(() => {
    getAlbumData()
  }, [])

  const getAlbumData = async () => {
    try {
      const response = await getSingleAlbum({ "album_id": albumId })
      const albumSongList = await getSongListOfUser({ "album_id": albumId })
      if (albumSongList) {
        setAlbumData(response.data.data) //get the albumdata
        // sort based on re-ordered value
        let arrayData = albumSongList.data[0].song;
        const data = arrayData.sort(function (a, b) {
          return parseInt(a.order) - parseInt(b.order);
        });
        setAlbumSongLibrary(data)
      }
    }
    catch (error) {
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      setTimeout(function () {
        dispatch(setSnackBar(false))
      }, 2000)
    }
  }

  const toggleReadMore = (e) => {
    e.preventDefault();
    setShowFullLyrics(!showFullLyrics);
  };

  const handleDownload = (songInfo) => {
    // Create a blob with song metadata
    const blob = new Blob([
      `Song Title: ${songInfo?.album_name}
                           \nExplicit Content:${songInfo?.explicit_content ? 'Yes' : 'No'}
                           \nGenre: ${(songInfo?.genre == "" || songInfo?.genre == undefined) ? "--" : songInfo.genre}
                           \nMood:${songInfo?.mood == "" || songInfo?.mood == undefined ? "--" : songInfo.mood}
                           \nISRC Code:${songInfo?.isrc_code == "" || songInfo?.isrc_code == undefined ? "--" : songInfo?.isrc_code}
                           \nSub Genre:${songInfo?.sub_genre == "" || songInfo?.sub_genre == undefined ? "--" : songInfo?.sub_genre}
                           \nInstrumental:${songInfo?.instrumental ? 'Yes' : 'No'}
                           \nUPC Code:${songInfo.upc_code != null ? songInfo.upc_code : ""}
                           \nPrimary Artist: ${(songInfo.primary_artists.length == 0 || songInfo.primary_artists == false) ? "--" : songInfo.primary_artists.map(p => p.artist_name).join(', ')}
                           \nFeaturing Artist: ${(songInfo.featuring_artists.length == 0 || songInfo.featuring_artists == false) ? "--" : songInfo.featuring_artists.map(p => p.artist_name).join(', ')}
                           \nComposer: ${(songInfo.composers.length == 0 || songInfo.composers == false) ? "--" : songInfo.composers.map(p => p.artist_name).join(', ')}
                           \nProducer: ${(songInfo.producers.length == 0 || songInfo.producers == false) ? "--" : songInfo.producers.map(p => p.artist_name).join(', ')}
                           \nActor: ${(songInfo.actors.length == 0 || songInfo.actors == false) ? "--" : songInfo.actors.map(p => p.artist_name).join(', ')}
                           \nLyricist: ${(songInfo.lyricists.length == 0 || songInfo.lyricists == false) ? "--" : songInfo.lyricists.map(p => p.artist_name).join(', ')}
                           \nRealease Date: ${songInfo.release_date ? getDateAsPerFormat(songInfo.release_date, "DD Mon, YYYY") : '--'}
                           \nLanguage: ${songInfo.language && songInfo.language}
                           \nRelease Time: ${songInfo.release_time ? getTime(songInfo.release_time) : ''}
                           \nSong Lyrics: ${songInfo.song_lyrics ? songInfo.song_lyrics : ''}
                           \nSong Description: ${songInfo.album_description ? songInfo.album_description : ''}
                           \n© Copyright Year": ${songInfo.copyright_c ? songInfo.copyright_c && songInfo.copyright_c.copyright_year.copyright_year : "--"}
                           \n© Copyright : ${songInfo.copyright_c ? songInfo.copyright_c.copyright_name : "--"}
                           \n℗ Copyright Year: ${songInfo.copyright_p ? songInfo.copyright_p && songInfo.copyright_p.copyright_year.copyright_year : "--"}
                           \n℗ Copyright: ${songInfo.copyright_p ? songInfo.copyright_p.copyright_name : "--"}
                           \nHas it been previously released?: ${songInfo.release_history === 'False' || songInfo.release_history === false ? 'No' : 'Yes'}
                           \nPrevious Release Date:${(songInfo.release_history === 'False' || songInfo.release_history === false) ? "--" : songInfo.previous_release_date == null || songInfo.previous_release_date == '' ? '--' : getDateAsPerFormat(songInfo.previous_release_date, "DD Mon, YYYY")}
                           \nPlatform:${platformList.length && platformList.filter(d => songInfo.platform?.includes(d.platformId)).map(d => (d.platformName))}
                          
                           ` ], { type: 'text/plain;charset=utf-8' });

    const url = URL.createObjectURL(blob);

    // Create a hidden link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = `${songInfo.album_name}.doc`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    getData(albumId)
    getPlatformList().then(res => {
      let response = res.data.data
      if (response) {
        setPlatformList(response)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      console.log(error);
      console.log(error.response);
      return error.response;
    })
  }, [])

  useEffect(() => {
    if (albumData.plan != undefined && albumData.plan != '') {
      getPlanList().then(res => {
        let response = res.data.data
        if (response) {
          setPlanList(response)
        }
      }).catch(function (error) {
        console.log(error);
        console.log(error.response);
        return error.response;
      })
    }
  }, [albumData.plan != undefined && albumData.plan != ''])

  function getGstAddedGSTAmount(amount, songCount) {
    const gstAmount = (amount * songCount * gstPercentage) / 100;
    const totalprice = amount * songCount + gstAmount;
    return Math.ceil(totalprice)
  }

  const handleRouteBack = () => {
    history.push('/albumlist')
  }

  function getData(albumId) {
    dispatch(setLoader(true));
    // getAprrover('GET')
    ApiService.get("getParticularAlbumApproval", { 'album_id': albumId })
      .then(function (response) {
        dispatch(setLoader(false));
        if (response.status === 200) {
          setIsApproved(response.data.data);
          let result = response.data.data.filter((item) => item.song_id.toString() === albumId);
          setFilterData(result);
        } else {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        }
      })
      .catch(function (error) {
        dispatch(setLoader(false));
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000);
        return error.response;
      });
  }

  const releaseSongLockinClickHandler = (e) => {
    e.preventDefault();
    if (albumId && filterData.length > 0) {
      setButtonText('Ready for Release');
      var postData = {};
      postData['comment'] = "Album is ready for release";
      postData['song_status'] = 'Ready for Release';
      postData['album_id'] = albumId;
      postData['approver_user_id'] = userData.id
      postData['is_approved'] = ''
      dispatch(setSnackBar(true));
      dispatch(setSnackBarVariant('info'));
      dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdating));
      dispatch(setLoader(true));
      // albumApprover(postData)
      ApiService.put("albumputApproval", postData)
        .then(function (response) {
          if (response.status === 200) {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('success'));
            dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdatingSuccess));
            setTimeout(() => dispatch(setSnackBar(false)), 2000);
            // dispatch(fetchUserDetail());
            getAlbumData()
          } else {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
          }
        })
        .catch((error) => {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('error'));
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        });
    } else {
      var albumPostData = {};
      albumPostData['song_status'] = 'Ready for Release';
      albumPostData['approver_user_id'] = userData.id;
      albumPostData['album_id'] = albumId;
      // albumApprover(albumId, albumPostData)
      ApiService.post("albumpostApproval", albumPostData)
        .then(function (response) {
          if (response.status === 200) {
            getAlbumData()
            // dispatch(fetchReleaseAlbum({ limit: 25, offset: 0 }, []))
          }
        })
    }
  }

  const releaseAlbumFinalClickHandler = (e) => {
    e.preventDefault();
    if (albumId) {
      var postData = {};
      postData['song_status'] = 'Released';
      postData['approver_user_id'] = userData.id;
      postData['album_id'] = albumId;
      dispatch(setSnackBar(true));
      dispatch(setSnackBarVariant('info'));
      dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdating));
      dispatch(setLoader(true));
      UpdateAlbumApprover(albumId, postData)
        .then(function (response) {
          if (response.status === 200) {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('success'));
            dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdatingSuccess));
            setTimeout(() => dispatch(setSnackBar(false)), 2000);
            // dispatch(fetchUserDetail());
            getAlbumData()
          } else {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
          }
        })
        .catch((error) => {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('error'));
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        });
    }
  }

  const downloadImage = (img) => {
    fetch(img)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', albumData?.album_name + ".jpg");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        console.error('Error downloading image:', error);
      });
  };


  function handleInputChange(event) {
    const { name, value } = event.target;
    setIsrcCode(value)
    const isValid = value.length > 0 ? regexValidator(value, 'alphanumeric') : true;
    if (!isValid)
      setErrors({ ...errors, isrc_code: true, isrc_code_helperTxt: formSnackbar.errors.validInput });
    else
      setErrors({ ...errors, isrc_code: false, isrc_code_helperTxt: false });
  }

  const isFieldsValid = (errObj) => {
    let array = [];
    for (let key in errObj) {
      array.push(errObj[key])
    }
    if (array.every((item) => item === false)) {
      return true;
    }
    else {
      return false;
    }
  }

  function saveIsrc() {
    if (isFieldsValid(errors) === true) {

    }

  }
  // ---------------UPC AND ISRC CODE START ----------

  const dialogHandler = (e, data, textname) => {
    setOpenDialog(true)
    setAlbumSongData({ value: data, label: textname })
  }


  function songListData(a, index) {
    return (
      <div className='mt-3 w-100' >
        <span className='d-block fw-bold mb-2'>{a?.song?.song_name}</span>
        <button onClick={(e) => dialogHandler(e, albumData, 'upc')} className="rounded-pill px-4 border-0 text-white ms-1 fs-08 py-2 gn-actionbtn shadow-none">{'Add Album UPC/EAN/JAN'}</button>
        <button onClick={(e) => dialogHandler(e, albumData, 'release')} className="rounded-pill px-4 border-0 text-white ms-1 fs-08 py-2 gn-actionbtn shadow-none">{'Update Release Date'}</button>
        <div className='d-flex align-items-center'>
          {smScreen ?
            <AudioPlayer className={'w-75'} page='page' type='small-releasealbumplayer' audioSrc={process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + a.song.song_file} /> : <AudioPlayer className={'w-75'} page='page' type='releasealbumplayer' audioSrc={process.env.REACT_APP_BASEURL + 'song' + a.song.song_file} />}
          <Tooltip title="Preview Song" arrow>
            <VisibilityIcon onClick={(e) => ViewSongMeta(e, a.song,)} className='mx-1 cursorPointer ' sx={{ color: "#ED3237" }} />
          </Tooltip>
          <button style={{ backgroundColor: getSongStatusBgColor(a.song.song_status) }} className="rounded-pill px-4 border-0 text-white ms-1 fs-08 py-2">{a.song.song_status == "Ready for Release" ? 'Approved' : a.song.song_status}</button>
          <button onClick={(e) => dialogHandler(e, a.song, 'isrc')} className="rounded-pill px-4 border-0 text-white ms-1 fs-08 py-2 gn-actionbtn shadow-none">{'Add Song ISRC'}</button>

        </div>
      </div>
    )
  }

  return (
    <div className='mx-4 mt-4'>
      <div className='d-flex justify-content-between'>
        <button className="icononly-btn d-flex mb-3" onClick={handleRouteBack}>
          <ArrowBackIcon /> <p className='cardheading ms-2'>{albumData.album_name != "" ? albumData.album_name : "--"}</p>
        </button>
        <div style={{ backgroundColor: getSongStatusBgColor(albumData.song_status) }} className="statusAlbum m-1 py-2 text-light">{albumData.song_status == "Ready for Release" ? 'Approved' : albumData.song_status}</div>
      </div>
      <div className='pb-5'>
        <div className='d-flex justify-content-between align-items-end'>

          <div className='mt-2' id="song-poster-cnt" style={{ position: 'relative' }}>
            <img src={albumData && albumData.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + albumData.cover_image : imgPlaceHolder} alt="Upload poster" className="albumPoster" />
            {albumData && albumData.cover_image != null ? <GetApp sx={{
              width: "18px",
              position: 'absolute',
              bottom: '10px',
              right: '10px',
              cursor: 'pointer'
            }} className='bg-white' onClick={() => downloadImage(albumData && albumData.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + albumData.cover_image : imgPlaceHolder)} />
              : ""}
          </div>

          <div >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='gap-2' >

              <BootstrapTooltip title='Download Album Meta' ransitioncomponent={Zoom} >
                <button className="gn-actionbtn fs-08"
                  onClick={() => handleDownload(albumData)}>
                  <GetApp sx={{ width: "18px" }} />
                </button>
              </BootstrapTooltip>

              {albumData.song_status == "In Review" || albumData.song_status == "" ?
                <BootstrapTooltip title='Ready for Release' ransitioncomponent={Zoom} >
                  <button
                    className="gn-actionbtn fs-08"
                    onClick={(e) => releaseSongLockinClickHandler(e)}
                    disabled={albumSongLibrary.length > 0 && albumSongLibrary.every(item => item.song.song_status === 'Ready for Release') ? false : true}>
                    <CampaignIcon />
                  </button>
                </BootstrapTooltip>
                : albumData.song_status == "Ready for Release" ?
                  <BootstrapTooltip title='Distribute Release' ransitioncomponent={Zoom} >
                    <button
                      className="gn-actionbtn fs-08"
                      onClick={(e) => releaseAlbumFinalClickHandler(e)}
                      disabled={albumSongLibrary.length > 0 && albumSongLibrary.every(item => item.song.song_status === 'Ready for Release') ? false : true}>
                      <img src={distribution} alt='distribution' />
                    </button>
                  </BootstrapTooltip>
                  : albumData.song_status == "Released" ?
                    "" : ""}
            </div>

          </div>
        </div>
        <div style={{ background: " #ECECEC" }} className='my-3 px-3 rounded pt-2 pb-3'>
          {albumSongLibrary.length > 0 && albumSongLibrary.map((a, index) => {
            return (
              <>
                {songListData(a, index)}
              </>
            )
          })}
          <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>

            <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100 pt-3' : 'px-2 pt-3'}`}>
              {/* <div className='d-flex align-items-center'>
              <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
            </div> */}
              <h5>Basic Information</h5>

              <div className='row mt-3'>
                <div className='col-md-8' style={{ paddingRight: '0.7rem' }}>
                  <div className="value-pair fs-09"><strong>Release Name: </strong><span className='truncate text-secondary'>{albumData.album_name != "" ? albumData.album_name : "--"}</span></div>
                  <div className="value-pair fs-09"><strong>Genre: </strong><span className='text-secondary'>{albumData.genre != null ? albumData.genre : "--"}</span></div>

                </div>
                <div className='col-md-4' style={{ paddingRight: '0.7rem' }}>
                  <div className="value-pair fs-09"><strong>Language: </strong><span className='text-secondary'>{albumData.language != null ? albumData.language : "--"}</span></div>
                  <div className="value-pair fs-09"><strong>Sub Genre: </strong><span className='text-secondary'>{(albumData.sub_genre != null || albumData.sub_genre != "" || albumData.sub_genre != undefined) ? albumData.sub_genre : "--"}</span></div>

                </div>

                {(albumData.album_description != "" || albumData.album_description != null || albumData.album_description != undefined) ? <div className='col-md-12'>
                  <div className="value-pair fs-09"><strong>Release Description: </strong><span className='text-secondary'>{albumData.album_description}</span></div>
                </div> : ""}

              </div>
            </div>

          </div>
          {/* row 2 */}

          {/* row 3*/}
          <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>
            <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100' : 'px-2'}`}>
              <div className='d-flex align-items-center'>
                <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
              </div>
              <h5>Release Credit</h5>
              {/* row 3*/}
              <div className='row mt-3'>
                <div className='col-md-4'>
                  <div className="value-pair fs-09"><strong>Primary Artist: </strong><span className='text-secondary'>{albumData.primary_artists?.length > 0 ? albumData.primary_artists.map(artist => artist.artist_name).join(', ') : "--"}</span></div>
                  <div className="value-pair fs-09"><strong>Lyricist: </strong><span className='text-secondary'>{albumData.lyricists?.length > 0 ? albumData.lyricists.map(artist => artist.artist_name).join(', ') : "--"}</span></div>
                </div>
                <div className='col-md-4'>
                  <div className="value-pair fs-09"><strong>Featuring Artist: </strong><span className='text-secondary'>{albumData.featuring_artists?.length > 0 ? albumData.featuring_artists.map(artist => artist.artist_name).join(', ') : "--"}</span></div>
                  <div className="value-pair fs-09"><strong>Producer: </strong><span className='text-secondary'>{albumData.producers?.length > 0 ? albumData.producers.map(artist => artist.artist_name).join(', ') : "--"}</span></div>
                  <div className="value-pair fs-09"><strong>Remixer: </strong><span className='text-secondary'>{albumData.remixers?.length > 0 ? albumData.remixers.map(artist => artist.artist_name).join(', ') : "--"}</span></div>
                </div>
                <div className='col-md-4'>
                  <div className="value-pair fs-09"><strong>Composer: </strong><span>{albumData.composers?.length > 0 ? albumData.composers.map(artist => artist.artist_name).join(', ') : "--"}</span></div>
                </div>

              </div>
            </div>
          </div>

          {/* row 4*/}
          <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>

            <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100' : 'px-2'}`}>
              <div className='d-flex align-items-center'>
                <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
              </div>
              <h5>Release Information</h5>
              {/* row 4*/}
              <div className='row mt-3'>
                <div className='col-md-6'>
                  <div className="value-pair fs-09"><strong>Has it been previously released?: </strong><span className='text-secondary'>{albumData.release_history === 'False' || albumData.release_history === false ? 'No' : 'Yes'}</span></div>
                  <div className="value-pair fs-09"><strong>© Copyright Year:</strong><span className='text-secondary'>  {albumData.copyright_c ? albumData.copyright_c && albumData.copyright_c.copyright_year.copyright_year : "--"}</span></div>
                  <div className="value-pair fs-09"><strong>© Copyright: </strong><span className='text-secondary'>{albumData.copyright_c ? albumData.copyright_c.copyright_name : "--"}</span></div>
                  <div className="value-pair fs-09"><strong>Release Time: </strong><span className='text-secondary'>{albumData.release_time ? getTime(albumData.release_time) : '--'}</span></div>
                  {(albumData.upc_code != "" || albumData.upc_code != undefined || albumData.upc_code != null) ? <div className="value-pair fs-09"><strong>UPC Code: </strong><span className='text-secondary'>{albumData.upc_code}</span></div> : "--"}
                </div>
                <div className='col-md-6'>
                  <div className="value-pair fs-09"><strong>Previous Release Date: </strong><span className='text-secondary'>{(albumData.release_history === 'False' || albumData.release_history === false) ? "--" : albumData.previous_release_date == null || albumData.previous_release_date == '' ? '--' : getDateAsPerFormat(albumData.previous_release_date, "DD Mon, YYYY")}</span></div>
                  <div className="value-pair fs-09"><strong>Ⓟ Copyright Year: </strong><span className='text-secondary'>{albumData.copyright_p ? albumData.copyright_p && albumData.copyright_p.copyright_year.copyright_year : "--"}</span></div>

                  <div className="value-pair fs-09"><strong>Ⓟ Copyright: </strong><span className='text-secondary'>{albumData.copyright_p ? albumData.copyright_p.copyright_name : "--"}</span></div>

                  <div className="value-pair fs-09"><strong>Release Date: </strong><span className='text-secondary'>{albumData.release_date == "" ? "--" : getDateAsPerFormat(albumData.release_date, "DD Mon, YYYY")}</span></div>
                </div>

              </div>
            </div>
          </div>
          <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>
            <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100' : 'px-2'}`}>
              <div className='d-flex align-items-center'>
                <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
              </div>
              <h5>Platforms</h5 >           {/* row 3*/}
              <div className='row mt-3'>
                {platformList.length && platformList.filter(d => albumData.platform?.includes(d.platformId)).map(d => (
                  <div key={d.platformId} className='col-md-4 p-2 fs-09'>
                    <span className='text-secondary'>{d.platformName}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {albumData.plan != '' && albumData.plan != undefined ?
            <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>
              <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100' : 'px-2 mb-3'}`}>
                <div className='d-flex align-items-center'>
                  <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                </div>
                <h5>Payment Detail</h5>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <div className="value-pair fs-09"><strong>Purchased Plan: </strong><span className='text-secondary'>{planList.length > 0 ? planList.find(d => d.id == albumData.plan)?.name : "--"}</span></div>
                    <div className="value-pair fs-09"><strong>Track Count: </strong><span className='text-secondary'>{albumSongLibrary.length ? albumSongLibrary.length : 0}</span></div>
                    <div className="value-pair fs-09"><strong>Payment Status </strong><span className='text-secondary'>Paid</span></div>
                  </div>
                  <div className='col-md-6'>
                    <div className="value-pair fs-09"><strong>Plan Price: </strong><span className='text-secondary'>{<CurrencyRupeeIcon className='mb-1' sx={{ fontSize: '15px' }} />}{planList.length > 0 ? planList.find(d => d.id == albumData.plan)?.price : "--"}</span></div>
                    <div className="value-pair fs-09"><strong>Total Paid Amount + 18% Gst: </strong><span className='text-secondary'>{<CurrencyRupeeIcon className='mb-1' sx={{ fontSize: '15px' }} />}{getGstAddedGSTAmount(planList.length > 0 ? planList.find(d => d.id == albumData.plan)?.price : null, albumSongLibrary.length)}</span></div>
                    <div className="value-pair fs-09"><strong>Revenue Share:</strong><span className='text-secondary'> {planList.length > 0 ? planList.find(d => d.id == albumData.plan)?.share_revenue : "--"}% </span></div>

                  </div>
                </div>


              </div>
            </div> : ''}
        </div>
      </div>
      <AlbumSongMetaDialog getAlbumData={getAlbumData} albumsongData={albumsongData} open={openDialog} setOpen={setOpenDialog} />
      <SnackbarContainer />
      </div>
  )
}

export default ReleaseFinalReview