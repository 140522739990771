import {
    FETCH_LOGIN_USER_FAILURE,
    FETCH_LOGIN_USER_REQUEST,
    FETCH_LOGIN_USER_SUCCESS
} from "./loginUserTypes";
const initialState = {
    loading: false,
    userDetail: "",
    // selectedQuestionList: null,
    error: '',
  }
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case   FETCH_LOGIN_USER_REQUEST:
        return {
          ...state,
          loading: true
        }
      case FETCH_LOGIN_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          userDetail: action.payload,
          error: ''
        }
      case FETCH_LOGIN_USER_FAILURE:
        return {
          ...state,
          loading: false,
          userDetail: [],
          error: action.payload
        }
       
      default: return state
    }
  }
  
  export default reducer
  