import * as React from 'react';
import { Box, Typography, TextField } from '@mui/material';

export default function Search({ headValue }) {

    return (
        <Box className='d-flex justify-content-between ps-3 py-2'>
            <Box>
                <Typography variant='h6'>{headValue}</Typography>
            </Box>
            {/* <Box>
                <TextField size='small' id="search" label="Search" variant="outlined" />
            </Box> */}
        </Box>
    );
}