import React from 'react'
import ThankYou from '../img/general/Thankyou.svg'
import backgroundImage from '../img/background/bg_image_texture6.png'
import logo from "../img/general/gn_logo_black_beta.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Link} from 'react-router-dom'
import axios from 'axios';

const SingupVerification = () => {
  return (
    <div className="vh-100"  style={{backgroundImage:`url(${backgroundImage})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
      <div className="fullpage-logo">
          <img src={logo}/>
      </div>
      <div className="d-flex justify-content-start align-items-center flex-column fullPage">
          <div className='text-left fullpage-content'>
              <img src={ThankYou} alt="Img Not found" />
              <div className="fullpage-inner-content">
                  <h1 className='mt-4' style={{fontWeight:'900'}}>For Signing up, you're almost done</h1>
                  <div className="m-4"></div>
                  <h3 className='mb-4 mt-4'>We've sent you an email, check your email inbox in few minutes.</h3>
                  <h5 className='mb-4 mt-4' style={{color: '#3333337a',fontWeight: '500'}}>Please confirm your account by clicking the link in the email</h5>
                  <Link to="/"><button className='gn-actionbtn mt-4 mb-4'><ArrowBackIcon /> Back To Home Page</button></Link>
              </div>
          </div>
      </div>
    </div>
  )
}

export default SingupVerification