import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "45rem",

    bgcolor: 'background.paper',
    border: '1px ',
    borderRadius: "6px",
    // boxShadow: 24,
    // p: 4,

};
function IssueCreate(props) {
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className=" px-4 py-2 bg-success">

                </Box>
            </Modal>
        </div>
    );
}

export default IssueCreate;