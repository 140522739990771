export const formSnackbar = {
    PRE_CATEGORY_UPDATE: 'category updating',
    POST_CATEGORY_UPDATE: 'category updated successsfully',
    PRE_GENRE_UPDATE: 'Genre updating',
    POST_GENRE_UPDATE: 'Genre updated successsfully',
    PRE_PROFILE_UPDATE: 'Profile updating',
    POST_PROFILE_UPDATE: 'Profile updated successsfully',
    PRE_PHONE_UPDATE: 'Phone Number updating',
    POST_PHONE_UPDATE: 'Phone Number updated successsfully',
    PRE_SOCIAL_UPDATE: 'Social Data updating',
    POST_SOCIAL_UPDATE: 'Social Data updated successsfully',
    PRE_SOCIAL_DELETE: 'Social Data Deleting',
    POST_SOCIAL_DELETE: 'Social Data Deleted successsfully',
    PRE_USERHANDLE_UPDATE: 'Profile updating',
    POST_USERHANDLE_UPDATE: 'Profile updated successsfully',
    PRE_EXPERIENCE_UPDATE: 'Experience  updating',
    POST_EXPERIENCE_UPDATE: 'Experience updated successsfully',
    PRE_EXPERIENCE_DELETE: 'Experience  deleting',
    POST_EXPERIENCE_DELETE: 'Experience deleted successsfully',
    PRE_CREATER_DELETE: 'Link  deleting',
    POST_CREATER_DELETE: 'Link deleted successsfully',
    PRE_CREATER_LINK_UPDATE: ' Creator Link  updating',
    POST_CREATER_LINK_UPDATE: 'Creator Link updated successsfully',
    PRE_REGISTER_MSG: 'User Registering......',
    POST_REGISTER_MSG: 'User Registered successsfully',
    creatorLink:{
      linkCreate:'Creating Link...',
      linkCreateSuccess:'Creator link created successfully',
      linkUpdate:'Updating link...',
      linkUpdateSuccess:'Creator link updated successfully',
      linkDelete:'Deleting link...',
      linkDeleteSuccess:'Creator link deleted successfully'
    },
    errors:{
      alreadyRegistered:'User with this email id is already registered',
      errorMsg:'Something went wrong! Your request has failed.',
      urlError:'Please enter a valid URL. Ex. `http://www.domain.com` ',
      imgUploadSize:'"Sorry, the dimensions of the image you uploaded are incorrect. Please upload an image that is 3000 pixels wide and 3000 pixels high."',
      imgUploadType:"Oops, it looks like you've uploaded the wrong file format. Please choose a valid .jpg or .png file.",
      fileUploadType:"Oops, it looks like you've uploaded the wrong file format. Please choose a valid .wav file.",
      fileUploadSize:"Sorry, the file size you uploaded is too large. Please choose a file that is under 50 megabytes in size.",
      characterCountErrorlow:'Maximum 100 chracters are allowed',
      characterCountErrorMax:'Maximum 4000 chracters are allowed',
      validInput:'Please enter a valid input',
      imgUploadAvatarSize:"Please upload an image that is between 200px and 300px wide and between 200px and 300px tall.",
      imgTypeAvatar:"Oops, it looks like you've uploaded the wrong file format. Please choose a valid .jpg or .png file.",
      invalidCredentials:'Invalid login credentials.',
      validPassword:'Please enter a valid password',
      validEmail:'Please enter a valid email',
      // tanya code start
      isrcAndUpcLength:'Maximum length 12 characters ',
      // end

    },
    verifyArtist:{
      verficationApply: 'Applying for verification', 
      verificationApplied:'Successfully applied for verification',
      verificationPending:'Your account verification is pending',
      verificationRejected:'Your application to verify your account has been rejected'
    },
    serviceRequest:{
        serviceRequestSuccess:'Success',
        serviceRequestFailure:'The service has responded with the error'
    },
    socialLink:{
      socialLinkCreate:'Creating social link',
      socialLinkSuccess:'Social link created Successfully',
      socialLinkUpdate: 'Updating social link',
      socialLinkUpdateSuccess: 'Social link updated successsfully',
      socialLinkDelete: 'Deleting social link',
      socialLinkDeleteSuccess: 'Social link Deleted successsfully',
    },
    songPlaylist:{
      songCreate:'Creating song link',
      songCreateSuccess:'Song link created successfully',
      songLinkUpdate:'Updating song link',
      songLinkUpdateSuccess:'Song link updated successfully',
      songLinkDelete:'Deleting song link',
      songLinkDeleteSuccess:'Song link deleted successfully'
    },
    playlist: {
      playlistCreate: 'Your playlist has been created',
      playlistDelete: 'Playlist has been deleted successfully',
      playlistUpdate: 'Playlist has been updated successfully',
      songRemove: 'Song has been removed from the playlist.',
      songRemoving: 'Removing song from the playlist.',
      playlistWarning: 'Song has been already added in the playlist',
      playlistTrendingAdding: "Adding playlist to trending. ",
      playlistTrendingAdded: "Playlist successfully added to trending. ",
      playlistTrendingRemove: "Removing a playlist from the trending ",
      songTrendingAdding: "Adding song to trending. ",
      songTrendingAdded: "Song successfully added to trending. ",
      songTrendingRemove: "Removing a song from the trending ",
    },
    relatedPlatform:{
      realtedPlatformCreate:'Adding platform',
      realtedPlatformCreateSuccess:'Platform added successfully',
      realtedPlatformUpdate:'Updating platform',
      realtedPlatformUpdateSuccess:'Platform updated successfully',
      relatedPlatformDelete:'Deleting platform',
      relatedPlatformDeleteSuccess:'Platform deleted successfully'
    },
    expertiseLink:{
      expertiseLinkUpdate:'Expertise updating',
      expertiseLinkUpdateSuccess:'Expertise updated successfully',
    },
    userlanguage:{
      languageUpdate:'Updating language',
      languageUpdateSuccess:'language updated successfully'
    },
    userAddress:{
      userAddressUpdate:'Updating address',
      userAddressUpdateSuccess:'Address updated successfully',
    },
    enquireForm:{
      enquireFormDelete:'Enquiry Deleting',
      enquireFormDeleteSuccess:'Enquiry Deleted successfully',
    },
    submitAsong:{
      ReleaseInfoCreate:'Submitting your song details...',
      ReleaseInfoCreateSuccess:'Your song has been submitted successfully',
      SongInfo:'Deleting your song...',
      SongInfoSuccess:'Song has beed deleted successfully',
    },

    submitDemo:{
      DemoUpload:'Submitting your demo...',
      DemoUploadSuccess:'Your demo has been submitted successfully',
      DemoDelete:'Deleting your demo...',
      DemoDeleteSuccess:'Demo has been deleted successfully',
    },
    associatedArtist:{
      artistUpload:'Artist adding',
      success:'Artist Added Successfully',
      // artistUpdate:'Artist Updating',
      artistUpdateSuccess:'Artist Update Successfully',
      // artistDelete:'Artist Deleting',
      artistDeletedSuccess:'Artist Deleted Successfully'
    },
    songAprrovedStatus:{
      statusUpdating:'Status Updating',
      statusUpdatingSuccess:'status updating successfully',
    },
    downloadMeta:{
      downloadUpdating:'Audio Downloading......',
       UpdatingSuccess:'Audio Download  successfully',
       
    },

}
