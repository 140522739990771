import React from 'react';
import Dashboard from '../dashboard/dashboard';
import artist1 from '../../img/artist1.png'
import artist2 from '../../img/artist2.png'
import artist3 from '../../img/artist3.png'
import artist7 from '../../img/artist7.png'
import artist5 from '../../img/artist5.png'
import artist6 from '../../img/artist6.png'
import { Link } from 'react-router-dom';
import Footer from "../../admin/footer";
import { backdropClasses } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Search } from '@mui/icons-material';
import { ArrowDownward } from '@mui/icons-material';
function Artists(props) {
    const history=useHistory();
    const data = [{ id: 1, artist: artist1, title: "Ed Sheeran", subTitle: "Lorem Ipsum is simply  text of the printing ." },
    { id: 2, artist: artist2, title: "Taylor Swift", subTitle: "Lorem Ipsum is simply  text of the printing." },
    { id: 3, artist: artist3, title: "DJ  Snake", subTitle: "Lorem Ipsum is simply  text of the printing " },
    { id: 1, artist: artist1, title: "Ed Sheeran", subTitle: "Lorem Ipsum is simply  text of the printing      " },
    { id: 5, artist: artist5, title: "DJ NYK", subTitle: "Lorem Ipsum is simply  text of the printing      " },
    { id: 6, artist: artist6, title: "DJ Chetas", subTitle: "Lorem Ipsum is simply  text of the printing      " },
    { id: 7, artist: artist7, title: "DJ Sartek", subTitle: "Lorem Ipsum is simply  text of the printing      " },
    { id: 1, artist: artist1, title: "Ed Sheeran", subTitle: "Lorem Ipsum is simply  text of the printing      " },
    ]
function back(){
    history.push("/services")
}
  
    return (
        <div>
            {/* <Dashboard> */}
                <div className="container-fluid p-0">
                    <div className="row">
                        <div onClick={back}>
                            <span className="h3"> &#8592;</span><span className="h6"> Back</span>
                        </div>
                        <div className="mt-4 mb-3 d-flex justify-content-between">
                            <div className="input-group Btn">
                                <input className="form-control  searchBtn border-2" value="search" id="example-search-input" />
                                <span className="input-group-append">
                                    <button className="btn btn-outline-secondary  border-0 ms-n5" type="button">
                                        <Search />
                                    </button>
                                </span>
                            </div>
                                <span className="input-group-append ">
                                    <button className="btn b     searchBtn sortBtn ms-n5 " >
                                    <ArrowDownward />Sort By
                                    </button>
                                </span>
                            </div>
                        <div className="row">
                            <h4>Artists</h4>
                            {data.map((item) =>
                                <div className="col-md-3 py-3">
                                    <div className="card">
                                        <img src={item.artist} />

                                        <div className="card-body">
                                            <h5>{item.title}</h5>
                                            <span>{item.subTitle}</span><Link>See More</Link>
                                        </div>
                                    </div>
                                </div>)}
                        </div>
                        <div className="p-4 text-center">
                            <button className="btn btn-secondary rounded-pill px-5">LOAD MORE</button>
                        </div>
                    </div>
                 
                </div>
            {/* </Dashboard>
            <Footer /> */}
        </div>
    );
}

export default Artists;