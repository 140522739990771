import axios from "axios";
import { deleteExperienceApi } from '../_constants/api.constant'
import {  getSelfToken } from '../../_helpers/auth/secureToken'
export async function deleteExperience(dataId) {
    const response = await axios.delete(
        deleteExperienceApi.DELETE+'?id='+dataId,
        {
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        //window.location.reload();
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}