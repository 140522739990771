import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CancelOutlined } from '@mui/icons-material'
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IssueCreate from './issueCreate';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "45rem",

  bgcolor: 'background.paper',
  border: '1px ',
  borderRadius: "6px",
  // boxShadow: 24,
  // p: 4,

};
const LabelTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
   
    // '&.Mui-focused': {
    //     backgroundColor: 'transparent',
    //     boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
    //     borderColor: theme.palette.primary.main,
    // },
  },
}));


function AskNow(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () =>{
    setOpen(true);
  } 
  const handleClose = () => setOpen(false);
  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className=" px-4 py-2">
          <span className="fs-2 float-end">&times;</span>
          <h4 className="py-4">Ask Question</h4>
          <div>
            <LabelTextField
              fullWidth
              label="Query Subject"
              defaultValue="Time machine"
              id="reddit-input"
              variant="filled"
              className="mb-3"
              // style={{ marginTop: 11 }}
            />
            <LabelTextField
              // id="filled-multiline-static"
              className="border mb-3"
              label="Query                                                                                                                                     Description"
              // style={{ marginTop: 11,}}
              fullWidth
              multiline
              rows={4}
              defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
              variant="filled"
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Assign Issue</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value="age"
                label="Assign Issue"
                onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <div className="p-4 text-center">
              <button className="btn btn-danger rounded  "onClick={handleOpen}>Submit</button>
            </div>
            <IssueCreate open={open} handleClose={handleClose}/>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default AskNow;