import React from 'react';
import { Public } from '@mui/icons-material'
import logo from "../img/logo1.png"
import profile from "../img/shweta.jpeg"
import Footer from './footer';
import { Link } from 'react-router-dom';
function SignUp(props) {
    return (
        <div className="container-fluid p-0">
            <div className="row">
                <nav className="navbar navbar-light  border pb-0 ">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#"><img src={logo} /></a>
                    </div>
                </nav>
                <div className="col-md-6 offset-md-3">
                    <div className="login-form  mt-4 p-4 border rounded shadow">
                        <div className='col-12 text-center pb-3'>
                            <img src={profile} className="signProfile" /><span className="ps-2">shweta@thehigherpitch.com</span>
                        </div>
                        <form action="" method="" className="row g-3">
                            <h2 className="text-center">Complete your account setup</h2>
                            <div className="col-12">
                                <div className='input-group'>
                                    <div className="input-group-prepend ">
                                        <span className="input-group-text bg-white text-secondary" id="basic-addon1"><Public /></span>
                                    </div>
                                    <input type="button" className="border bg-white border-start-0 form-control" placeholder='Country' data-bs-toggle="dropdown" aria-expanded="false" />
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="btn-group w-100 continueGoogle rounded" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" defaultChecked />
                                    <label className="btn text-white  pt-2" htmlFor="btnradio1">Music Artist</label>
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                                    <label className="btn text-white pt-2 " htmlFor="btnradio2">Infulencer</label>
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                                    <label className="btn  text-white pt-2" htmlFor="btnradio3">Micro Influencer</label>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input checkbox" id="exampleCheck1" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Yes, I understand and agree to the GrooveNexus Services</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <Link to="/dashboard"><button type="submit" className="btn btn-danger float-end w-25">Submit</button></Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
          <Footer/>
        </div>
    );
}

export default SignUp;