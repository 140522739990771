import logo from './logo.svg';
import React, { createContext, useEffect, useState } from 'react';
import './App.css';
import Login from './admin/login';
import SignUp from './admin/signUp';
import Dashboard from './module/dashboard/dashboard';
import Register from './admin/register';
import InvoiceList from '../src/module/invoice/InvoiceList'
import Invoice from '../src/module/invoice/Invoice'
import { Backdrop, } from '@mui/material';
import { CircularProgress } from '@mui/material';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Redirect,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import Services from './module/services/services';
import ServicesPlan from './module/services/servicesPlan';
import Artists from './module/services/artists';
import ShoppingCart from './module/services/shoppingCart';
import Helpdesk from './module/helpdesk/helpdesk';
import TrackTicket from './module/helpdesk/trackTicket';
import Issue from './module/helpdesk/issue';
import PrivateRoute from './PrivateRoute';
import Order from "../src/module/order/Order";
import Subscription from './module/subscription/subscription';
import Placement from './module/placement/placement';
import { useSelector, useDispatch } from 'react-redux'
// import { setLoader } from './redux';
import { Card } from '../src/module/card'
import RegisterUsers from './admin/RegisterUsers';
import { removeUserToken } from './_helpers/auth/secureToken'
import Forgot from './admin/forgot';
import ResetPassword from './admin/resetPassword';
import ChangePassword from './admin/changePassword';
import PageNotFound from './admin/PageNotFound';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SingupVerification from './admin/SingupVerification';
import SongList from './module/submitSong/SongList';
import SongDetails from './module/submitSong/SongDetails';
import ArtistList from './module/artist/ArtistList';
import AudioPlayer from './components/reusableComponents/customAudioPlayer';
import ArtistDetail from './module/artist/ArtistDetail';
import DemoList from './module/submitDemo/DemoList';
import AlbumList from './module/AlbumList/AlbumList';
import AlbumDetail from './module/AlbumList/AlbumDetails';
import StudioList from './module/studio/studioList';
import StudioCardDetail from './module/studio/cardformate/studioCardDetail';
import ImportRevenueList from './module/importRevenue/ImportRevenueList';
import Royalty from './module/royalty/Royalty';
import Account from './module/account/Account';
import ViewRevenue from './module/importRevenue/ViewRevenue';
import PayoutList from './module/importRevenue/PayoutList';
import EditRevenue from './module/importRevenue/EditRevenue';
import Details from './module/royalty/Details';
import PayoutListDetail from './module/importRevenue/PayoutListDetail';
import ReleaseFinalReview from './module/AlbumList/ReleaseFinalReview';
import ReleaseList from './module/AlbumList/ReleaseList';
import SongpitchView from './module/pitchSong/SongpitchView';
import SongpitchTable from './module/pitchSong/SongpitchTable';
import Discover from './module/discover/Discover';
import PlaylistDetail from './module/discover/PlaylistDetail';
import DiscoverMusic from './module/discover/DiscoverMusic';
import MusicPlatforms from './module/discover/MusicPlatforms';


function useQuery() {
  return new URLSearchParams(window.location.search);

}
const theme = createTheme({
  palette: {
    secondary: {
      main: '#333'
    }
  },
});
function App() {
  let query = useQuery();
  const [userData, setUserData] = useState([]);
  const [callbackStatus, setCallbackStatus] = useState(query.get("callback"));
  const dispatch = useDispatch();
  const openBackdrop = useSelector((state) => state.loader.loader);
  let authenticationUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVER}/logout?callback=${window.location.href}`;

  function loginStatus() {
    let status = localStorage.getItem("auth");
    if (status) {
      return true;
    }
    else {
      return false;
    }
  }


  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Backdrop open={openBackdrop} sx={{ color: '#fff', zIndex: 9999 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Switch>
          ------------------**********************---------------
          <PrivateRoute exact path="/card" >
            <Card />
          </PrivateRoute>
          {/* <PrivateRoute exact path="/createrDemo" >
          <Creator />
        </PrivateRoute> */}
          -------------------*********************-------------------
          {/* <PrivateRoute exact path="/service-plan" >
          <ServicesPlan />
        </PrivateRoute> */}
          <PrivateRoute exact path="/dashboard" loginStatus={loginStatus}>
            <Dashboard />
          </PrivateRoute>
          {/* <PrivateRoute exact path="/creator" loginStatus={loginStatus}>
          <Creater />
        </PrivateRoute> */}
          {/* <PrivateRoute exact path="/playlist" loginStatus={loginStatus}>
          <SongListComp />
        </PrivateRoute> */}

          <PrivateRoute exact path="/submitsong" loginStatus={loginStatus}>
            <SongList />
          </PrivateRoute>
          <PrivateRoute exact path="/albumlist" loginStatus={loginStatus}>
            <ReleaseList />
            {/* <AlbumList /> */}
          </PrivateRoute>
          {/* <PrivateRoute exact path="/albumdetail/:albumId" loginStatus={loginStatus}>
            <AlbumDetail />
          </PrivateRoute> */}
          <PrivateRoute exact path="/albumdetail/:albumId" loginStatus={loginStatus}>
            <ReleaseFinalReview />
          </PrivateRoute>
          <PrivateRoute exact path="/submitsong/:songId/:albumId" loginStatus={loginStatus}>
            <SongDetails />
          </PrivateRoute>
          <PrivateRoute exact path="/submitdemo" loginStatus={loginStatus}>
            <DemoList />
          </PrivateRoute>
          <PrivateRoute exact path="/importrevenue" loginStatus={loginStatus}>
            <ImportRevenueList />
          </PrivateRoute>
          <PrivateRoute exact path="/viewrevenue" loginStatus={loginStatus}>
            <ViewRevenue />
          </PrivateRoute>
          <PrivateRoute exact path="/discover-playlist" loginStatus={loginStatus}>
            <Discover />
          </PrivateRoute>
          <PrivateRoute exact path="/discover-music" loginStatus={loginStatus}>
            <DiscoverMusic />
          </PrivateRoute>
          <PrivateRoute exact path="/playlist-detail" loginStatus={loginStatus}>
            <PlaylistDetail />
          </PrivateRoute>
          <PrivateRoute exact path="/music-detail" loginStatus={loginStatus}>
            <MusicPlatforms />
          </PrivateRoute>
          <PrivateRoute exact path="/analytics/:studioId" loginStatus={loginStatus}>
            <Royalty />
          </PrivateRoute>
          <Route exact path="/details" loginStatus={loginStatus}>
            <Details />
          </Route>
          <Route exact path="/details/:studioId" loginStatus={loginStatus}>
            <Details />
          </Route>
          <PrivateRoute exact path="/editrevenue" loginStatus={loginStatus}>
            <EditRevenue />
          </PrivateRoute>
          <PrivateRoute exact path="/payout" loginStatus={loginStatus}>
            <PayoutList />
          </PrivateRoute>
          <PrivateRoute exact path="/payout-detail" loginStatus={loginStatus}>
            <PayoutListDetail />
          </PrivateRoute>
          <PrivateRoute exact path="/account" loginStatus={loginStatus}>
            <Account />
          </PrivateRoute>

          <PrivateRoute exact path="/customaudio" loginStatus={loginStatus}>
            <AudioPlayer />
          </PrivateRoute>



          {/* <ArtistData.Provider value={{userData,getUserData}}>   */}
          <PrivateRoute exact path="/" loginStatus={loginStatus}>
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/artistlist" loginStatus={loginStatus}>
            <ArtistList />
          </PrivateRoute>
          <PrivateRoute exact path="/studiolist" loginStatus={loginStatus}>
            <StudioList />
          </PrivateRoute>
          <PrivateRoute exact path="/studio-view-detail" loginStatus={loginStatus}>
            <StudioCardDetail />
          </PrivateRoute>
          {/* <PrivateRoute exact path="/artistdetail/:artistId" loginStatus={loginStatus}>
          <ArtistDetail />
        </PrivateRoute> */}
          {/* </ArtistData.Provider> */}
          {/* ------------------pitching navigation path ----------- */}

          <PrivateRoute exact path="/songpitchView/:songId" loginStatus={loginStatus}>
            <SongpitchView />
          </PrivateRoute>

          <PrivateRoute exact path="/songpitch" loginStatus={loginStatus}>
            <SongpitchTable />
          </PrivateRoute>

          <PrivateRoute exact path="/artistdetail/:userHandle" loginStatus={loginStatus}>
            <ArtistDetail />
          </PrivateRoute>
          {/*  <PrivateRoute exact path="/" loginStatus={loginStatus}>
          <Profile />
        </PrivateRoute> */}
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/user/activate/:tokenKey/:tokenValue">
            <Login />
          </Route>
          <Route exact path="/thank-you/">
            <SingupVerification />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
          <Route exact path="/registerUser">
            <RegisterUsers />
          </Route>
          <Route exact path="/sign-up">
            <SignUp />
          </Route>
          {/* <Route exact path="/helpdesk">
          <Helpdesk />
        </Route>
        <Route exact path="/track-ticket">
          <TrackTicket />
        </Route>
        <Route exact path="/issue">
          <Issue />
        </Route>
        <Route exact path="/issue">
          <Issue />
        </Route> */}
          <Route exact path="/forgot-password">
            <Forgot />
          </Route>
          <Route exact path="/change-password">
            <ChangePassword />
          </Route>
          <Route exact path="/user/passwordreset/:rowId/:token" >
            <ResetPassword />
          </Route>
          {/* <Route exact path="/logout">
          <Issue />
        </Route> */}
          <Route path="/logout" render={() => {
            removeUserToken();
            let callbackUrl = "/login";
            return <Redirect to={{ pathname: callbackUrl }} />;
          }
          }
          />
          <Route path="*">
            <PageNotFound />
          </Route>
          {/* <Redirect from="*" to="/" /> */}
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
