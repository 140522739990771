import React from 'react';
import Dashboard from '../dashboard/dashboard';

function ServicesPlan(props) {
    return (
        <div className="bg-light">
            {/* <Dashboard> */}
                <div className="container-fluid p-0">
                    <div className="row">
                        <h5>services</h5>
                   
                            <h4>Artist Press Kit service</h4>
                            <div className="col-md-4">
                                <div className="card border">
                                    <div className="card-body">

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card border">
                                    <div className="card-body">

                                    </div>
                                </div>
                            </div>
                     
                    </div>

                </div>
            {/* </Dashboard> */}
        </div>
    );
}

export default ServicesPlan;