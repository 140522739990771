import React from 'react';
import { useHistory } from "react-router-dom"
import Dashboard from '../dashboard/dashboard';
import avatar from "../../img/shweta.jpeg"
function Issue(props) {
    const data = [
        { id: 1, user: avatar, name: "Admin", chat: "Hey will we contact youb ASAP" },
        { id: 2, user: avatar, name: "Sonal", chat: "Thanku so much !" },
        { id: 3, user: avatar, name: "Admin", chat: "This is your UN Number 3249873265 ,Hope that worked" },

    ]
    const history = useHistory()
    function back() {
        history.push("/track-ticket")
    }
    function NewTicket() {
        history.push("/track-ticket")
    }
    function CloseTicket() {
        history.push("/helpdesk")
    }

    return (
        <div className="">
            {/* <Dashboard> */}
                <div className='container-fluid p-0'>
                    <div className="row">
                        <div onClick={back}>
                            <span className="h3"> &#8592;</span><span className="h6"> Back</span>
                        </div>
                        <div className="d-flex justify-content-between pt-4">
                            <div>
                                <h4>Issue</h4>
                                <p className="text-secondary">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                            </div>
                            <div>
                                <button className="btn btn-primary" onClick={NewTicket}>New Ticket</button>
                                <button className="btn btn-danger ms-4" onClick={CloseTicket}>Close Ticket</button>

                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-7 ">
                            <div className="card border rounded">
                                <div className="card-body py-4 px-4 rounded">
                                    <h6> Can I get the details of my PF account?</h6>
                                    <p className="text-secondary">Seriously guys i need it, please let me know as soon as you can provide.</p>
                                    <input type="text"
                                        className="w-100 rounded border"
                                        cols="40"
                                        rows="5"
                                        placeholder='Reply'
                                        style={{ height: "60px" }}
                                        // name="Text1"
                                        id="Text1"
                                        value="" />
                                    <button className="btn btn-danger px-4 mt-4 rounded">Send</button>
                                </div>
                            </div>
                            <div className="chatBox">
                                {data.map((d) =>

                                    <div className=" row mt-4 p-3">
                                        <div className="col-md-2">
                                            <div><img src={d.user} className="rounded-circle user-img" /></div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="p-2 px-4 chat">
                                                <h6 className="text-primary">{d.name}</h6>
                                                <span>{d.chat}</span>
                                            </div>
                                            <span className='float-end small'>10/22/2021  05:25 pm</span>
                                        </div>

                                    </div>

                                )}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">#5436843</th>
                                                <th scope="col">
                                                    <button className="btn btn-success rounded-pill  py-0 px-3">In Progress</button>
                                                </th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody className="p-2">
                                            <tr>
                                                <th scope="row" className="text-secondary">Priority :</th>
                                                <td>Urgent</td>
                                             
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-secondary">Assigned to :</th>
                                                <td>I don't know</td>
                                             
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-secondary">Date :</th>
                                                <td>10/21/2021</td>
                                            </tr>
                                            <tr>
                                                <th scope="row "className="text-secondary">Time :</th>
                                                <td>10:30AM</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-secondary">Close Date :</th>
                                                <td>10:30PM</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-secondary">Issue open from  :</th>
                                                <td>Two days</td>
                                            </tr>   
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </Dashboard> */}
        </div>
    );
}

export default Issue;